import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { HttpClient } from '@/core'
import { AdapterModel } from './AdapterModel'

export const adapter: AdapterModel.Func = (data) => {
  const errorData = data as AdapterModel.ResponseDataError
  /**
   * Сервер всегда возвращает status=200 в заголовке
   * Реальный статус приходит в теле ответа
   * 401 или 500+ считаем rejected ответами с наличием критической ошибки
   */
  if (errorData?.statusCode === 401 || errorData?.statusCode >= 500) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: errorData.errorDetails
        ? errorData.errorDetails.map(({ error }) => ({
            type: error,
            message: error,
          }))
        : [],
    }
    throw HttpClient.createError(errorData?.statusCode, exception)
  }

  try {
    const errorData = data as AdapterModel.ResponseDataError
    /**
     * Есть ошибки от сервера
     */
    if (errorData.errors && errorData.errors.length) {
      return {
        type: ResponseType.ResolveWithSystemError,
        systemErrors: errorData.errorDetails.map(({ error }) => ({
          type: error,
          message: error,
        })),
      }
    }

    const successData = data as AdapterModel.ResponseDataSuccess

    return {
      type: ResponseType.ResolveWithData,
      resultCode: successData.resultCode,
      sessionUID: successData.sessionUID,
      mobilePhone: successData.mobilePhone,
    }
  } catch (error) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [{ message: 'Ошибка при обработке полученных данных', type: 'ADAPTER_ERROR' }],
    }

    throw HttpClient.createError(500, exception)
  }
}
