import React from 'react'
import { Body12 } from '@platform-ui/typography'
import styled from '@emotion/styled'
import { ButtonBase } from '@platform-ui/components/ButtonBase'
import { ChevronRightIcon } from '@platform-ui/icons'

export const TabListDialogMoreWrapper = styled(ButtonBase)(({ theme }) => ({
  background: theme.color['surface/main'],
  margin: '2px 0',
  width: '100%',
  padding: '14px 0',

  ':not(:last-child)': {
    borderBottom: `1px solid ${theme.color['surface/bravo']}`,
  },
  ':first-of-type': {
    paddingTop: 0,
  },
}))

export const TabListDialogMoreIconButton = styled.div(({ theme }) => ({
  background: theme.color['surface/secondary'],
  borderRadius: 20,
  width: '100%',
  maxWidth: 40,
  height: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const TabListDialogMoreTextWrapper = styled.div(() => ({
  padding: '16px 0',
  textAlign: 'left',
  marginLeft: 16,
}))

export const TabListDialogMoreDesc = styled(Body12)(({ theme }) => ({
  color: theme.color['text/secondary'],
  marginTop: 4,
}))

export const TabListDialogMoreRightIcon = styled(ChevronRightIcon)(() => ({
  marginLeft: 'auto',
}))
