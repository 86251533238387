import styled from '@emotion/styled'
import { Body16, Body14 } from '@platform-ui/typography'

export const SmsCodeDesc = styled(Body16)(() => {
  return {
    marginTop: 32,
    textAlign: 'center',
  }
})

export const MismatchText = styled(Body14)(({ theme }) => {
  return {
    marginTop: 32,
    color: theme.color['text/error'],
  }
})

export const ButtonWrapper = styled.div(() => ({
  position: 'fixed',
  padding: '32px 16px',
  width: '100%',
  left: 0,
  bottom: 0,
}))
