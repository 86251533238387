import styled from '@emotion/styled'
import { ThemeColor } from '@emotion/react'
import { Button } from '@platform-ui/components'

export const TabListWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  background: '#F2F4FF',
  padding: '16px',
  marginTop: '8px',
  marginBottom: '16px',
  borderRadius: '12px',

  [`${theme.media.md}`]: {
    marginTop: 0,
    padding: '24px',
    marginBottom: '40px',
    borderRadius: '12px',
  },
}))

export const TextPrimary = styled.p(({ theme }) => ({
  ...theme.typography['headline/20'],
  display: 'flex',
  alignItems: 'flex-end',
  color: 'inherit',
  cursor: 'default',

  [`${theme.media.md}`]: {
    ...theme.typography['headline/28'],
  },
}))

export const TextAdditional = styled.p(({ theme }) => ({
  ...theme.typography['body/12'],
  color: '#797F90',
  cursor: 'default',
  marginBottom: theme.spacing.s4,

  [`${theme.media.md}`]: {
    ...theme.typography['headline/14'],
    marginBottom: theme.spacing.s4,
  },
}))

export const TextSecondary = styled.p(({ theme }) => ({
  ...theme.typography['headline/16'],
  display: 'flex',
  alignItems: 'center',
  color: 'inherit',
  cursor: 'default',
  gap: '16px',

  [`${theme.media.md}`]: {
    ...theme.typography['headline/20'],
  },
}))

export const BottomContent = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
}))
export const TopContent = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing.s8,
  gap: '8px',
}))

export const DateInfoIcon = styled.div<{ bgColor: ThemeColor }>(({ theme, bgColor }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 'none',
  width: '40px',
  height: '40px',
  marginRight: '16px',
  fontSize: 0,
  borderRadius: '50%',
  background: theme.color[bgColor],
}))

export const SignButton = styled(Button)(() => ({
  width: '155px',
  height: '40px',
  fontSize: '14px',
  whiteSpace: 'nowrap',
  borderRadius: '8px',
}))
