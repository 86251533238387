import { HttpClientModel } from '@/core'
import { ResponseType } from '@/models/ResponseType'
import { ResolveWithSystemErrorResponse } from '@/models/ResolveWithSystemErrorResponse'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'

export namespace PinCheckSmsModel {
  export type Func = (props: Props) => Promise<Response>
  export interface Props {
    pin: string
    smsCode: string
    sessionUID: string
  }
  export type Response = HttpClientModel.Response<ResponseData>
  export type ErrorResponse = HttpClientModel.ErrorResponse<ErrorResponseData>
  export type ResolveWithDataResponseSuccess = {
    type: ResponseType.ResolveWithData
    result?: Result
  }

  export type Result = ResultMatch | ResultMismatch | ResultError

  export interface ResultMatch {
    resultCode: ResultCode.Match
  }

  export interface ResultMismatch {
    resultCode: ResultCode.Mismatch
    resendSmsAvailable: boolean
  }

  export interface ResultError {
    resultCode: ResultCode.Error
  }

  export enum ResultCode {
    Mismatch = 'MISMATCH_SMSCODE',
    Match = 'MATCH_SMSCODE',
    Error = 'Error',
  }

  export type ResponseData = ResolveWithDataResponseSuccess | ResolveWithSystemErrorResponse
  export type ErrorResponseData = RejectedWithSystemErrorResponse
  export type RequestData = {
    pin: string
    smsCode: string
  }
  export type RequestHeaders = null
}
