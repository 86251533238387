import { ResponseType } from '@/models/ResponseType'
import { createSelector } from 'reselect'
import { CardsMcpModel } from '@/api/products/cardsMcp'
import { useCardsMcpQuery } from '@/api/products/cardsMcp'
import { UseQueryResult } from 'react-query'
import { SystemError } from '@/models/SystemError'
import { ApiStatus } from '@/core'
import _keyBy from 'lodash/keyBy'

type State = {
  debitCards: UseQueryResult<CardsMcpModel.ResponseData, CardsMcpModel.ErrorResponse>
}
const selector = createSelector(
  (state: State) => state.debitCards.data,
  (state: State) => state.debitCards.status,
  (state: State) => state.debitCards.refetch,
  (
    debitCardsResponse,
    debitCardsStatus,
    debitCardsRefetch
  ): {
    retry: () => void
    hasAnyResult: boolean
  } & (
    | {
        status: ApiStatus.Idle | ApiStatus.Pending
        products: any
      }
    | {
        status: ApiStatus.Rejected
        products: any
        error: SystemError<'NETWORK' | 'PARTIAL_RESPONSE' | 'IS_EMPTY_PRODUCTS'>
      }
    | {
        status: ApiStatus.Fulfilled
        products: Record<string, CardsMcpModel.CardsMcp>
      }
  ) => {
    const retryFns: (() => void)[] = []

    let products: CardsMcpModel.CardsMcp[] = []

    let hasNetworkError = false
    let hasPartialResponse = false
    let status = ApiStatus.Idle

    if (debitCardsStatus === 'success') {
      status = ApiStatus.Fulfilled
    }

    if (debitCardsStatus === 'error') {
      status = ApiStatus.Rejected
      retryFns.push(debitCardsRefetch)
      hasNetworkError = true
    }

    if (debitCardsStatus === 'loading') {
      status = ApiStatus.Pending
    }

    if (debitCardsResponse) {
      if (debitCardsResponse.type === ResponseType.ResolveWithData) {
        products = products.concat(
          ...debitCardsResponse.products.filter(
            (product) => product.cardStatus !== CardsMcpModel.CardStatusDisplayed.Unknown
          )
        )
      } else {
        hasPartialResponse = true
        retryFns.push(debitCardsRefetch)
      }
    }

    const error: SystemError<'NETWORK' | 'PARTIAL_RESPONSE' | 'IS_EMPTY_PRODUCTS'> | null =
      hasNetworkError
        ? {
            type: 'NETWORK',
            message: 'При получении данных произошла ошибка',
          }
        : hasPartialResponse
        ? {
            type: 'PARTIAL_RESPONSE',
            message: 'Ведутся сервисные работы',
          }
        : status === ApiStatus.Fulfilled && !products.length
        ? {
            type: 'IS_EMPTY_PRODUCTS',
            message: 'Список продуктов пуст',
          }
        : null

    const hasProducts = products.length > 0
    const productsMap = _keyBy(products, 'id')

    return {
      error: error,
      products: productsMap,
      status,
      retry: () => {
        retryFns.forEach((item) => {
          item()
        })
      },
      hasAnyResult: hasProducts || !!error,
    }
  }
)

export function useDebitCardsSelector() {
  const debitCards = useCardsMcpQuery()
  return selector({ debitCards: debitCards })
}
