import { ResponseType } from '@/models/ResponseType'
import { createSelector } from 'reselect'
import { UseQueryResult } from 'react-query'
import { SystemError } from '@/models/SystemError'
import { ApiStatus } from '@/core'
import { CreditCardsModel, useCreditCardsQuery } from '@/api/products/creditCards'

interface State {
  creditCard: UseQueryResult<CreditCardsModel.ResponseData, CreditCardsModel.ErrorResponse>
  productId: string
}

const selector = createSelector(
  (state: State) => state.creditCard.data,
  (state: State) => state.creditCard.status,
  (state: State) => state.creditCard.refetch,
  (state: State) => state.productId,
  (
    creditCardsResponse,
    creditCardsStatus,
    creditCardsRefetch
  ): {
    error: SystemError<'NETWORK' | 'IS_EMPTY_PRODUCTS'> | null
    status: ApiStatus
    retry: () => void
    productById: CreditCardsModel.CreditCard
    productNotFound: boolean
  } => {
    const retryFns: (() => void)[] = []

    let product: CreditCardsModel.CreditCard

    let hasNetworkError = false
    let status = ApiStatus.Idle

    if (creditCardsStatus === 'success' || creditCardsStatus === 'error') {
      status = ApiStatus.Fulfilled
    }

    if (creditCardsStatus === 'loading') {
      status = ApiStatus.Pending
    }

    if (creditCardsStatus === 'error') {
      hasNetworkError = true
    }

    if (creditCardsStatus === 'error') {
      retryFns.push(creditCardsRefetch)
    }

    if (creditCardsResponse && creditCardsResponse.type === ResponseType.ResolveWithData) {
      product = creditCardsResponse.product
    }

    const error: SystemError<'NETWORK' | 'IS_EMPTY_PRODUCTS'> | null = hasNetworkError
      ? {
          type: 'NETWORK',
          message: 'При получении данных произошла ошибка',
        }
      : status === ApiStatus.Fulfilled && !product
      ? {
          type: 'IS_EMPTY_PRODUCTS',
          message: 'Список продуктов пуст',
        }
      : null

    return {
      error: error,
      productById: product,
      productNotFound: !product,
      status,
      retry: () => {
        retryFns.forEach((item) => {
          item()
        })
      },
    }
  }
)

export function useGetCreditCardProductById(productId: string, cardMbr: number) {
  const creditCard = useCreditCardsQuery({ maskCardNumber: productId, cardMbr: cardMbr })

  return selector({ creditCard, productId })
}
