import styled from '@emotion/styled'
import { Body14, Body16, Headline16, Headline28 } from '@platform-ui/typography'
import { Shimmer } from '@platform-ui/components/Shimmer'

export const ProgressBarWrapper = styled.div<{ isError?: boolean }>(({ theme, isError }) => ({
  background: theme.color['surface/secondary'],
  borderRadius: 12,
  padding: 12,
  margin: '16px 0',
  textAlign: isError ? 'center' : 'left',

  [`${theme.media.md}`]: {
    padding: 20,
  },
}))

export const ProgressBarTitle = styled(Headline28)(() => ({
  fontWeight: 600,
}))

export const ProgressBarDesc = styled(Body16)(() => ({
  fontSize: 14,
  lineHeight: '18px',
  marginTop: 8,
}))

export const ProgressBar = styled.div(({ theme }) => ({
  width: '100%',
  height: '8px',
  background: theme.color['surface/bravo'],
  borderRadius: 8,
  margin: '12px 0 8px',

  [`${theme.media.md}`]: {
    margin: '16px 0 8px',
  },
}))

export const ProgressBarInner = styled.div<{ completed: number }>(({ theme, completed }) => ({
  width: `${completed * 100}%`,
  height: '100%',
  background: theme.color['surface/primary'],
  borderRadius: 8,
}))

export const ProgressBarAmountWrapper = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  'div:last-child': {
    textAlign: 'right',
  },
}))

export const ProgressBarAmount = styled(Headline16)(() => ({
  fontSize: 14,
}))

export const ProgressBarAmountText = styled(Body14)(({ theme }) => ({
  color: theme.color['text/secondary'],
  fontSize: 12,
}))

export const ShimmerWrapper = styled.div(({ theme }) => ({
  backgroundColor: theme.color['surface/tertiary'],
  borderRadius: 12,
  padding: '16px',
  margin: '16px 0 32px 0',
}))

export const ShimmerTitle = styled(Shimmer)(() => ({
  height: 32,
  width: 226,
  borderRadius: 12,
  marginBottom: 12,
}))

export const ShimmerSubTitle = styled(Shimmer)<{ shimmerWidth?: string }>(
  ({ shimmerWidth = '100%' }) => ({
    height: 16,
    width: `${shimmerWidth}`,
    borderRadius: 12,
    marginBottom: 8,
  })
)

export const ShimmerDesc = styled(Shimmer)(() => ({
  height: 8,
  borderRadius: 8,
  marginBottom: 8,
  marginTop: 12,
}))

export const ProgressBarAmountShimmer = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}))

export const ShimmerPeriod = styled(Shimmer)(() => ({
  height: 64,
  borderRadius: 12,
  padding: '16px',
  margin: '16px 0 32px 0',
}))
