import { MutateOptions, useMutation } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { SendSmsModel } from './SendSmsModel'
import { adapter } from './adapters/adapter'
import { stringTemplate } from '@/utils/string-template'

export const sendSms: SendSmsModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.cardActivation.sendSms,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.post<
    SendSmsModel.ResponseData,
    void,
    SendSmsModel.RequestHeaders,
    SendSmsModel.ErrorResponse
  >({
    url: stringTemplate<{ sessionUID: string }>(path, {
      sessionUID: props.sessionUID,
    }),
    data: null,
    headers: null,
    responseAdapter: adapter,
    control,
  })

  return response
}

export function useSendSmsMutation(
  options?: MutateOptions<SendSmsModel.Response, SendSmsModel.ErrorResponse, SendSmsModel.Props>
) {
  return useMutation(sendSms, options)
}
