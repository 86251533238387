import React from 'react'
// import { ResponseType } from '@/models/ResponseType'
// import { usePaymentsDetalizationQuery } from '@/api/products/paymentsDetalization'
import { ProductsModel } from '@/api/products/products'
import { formatDate } from '@/utils/formatDate'
import { PeriodWrapper, PeriodTitle, PeriodDesc } from './styled'

export interface PeriodProps {
  stage: ProductsModel.ConsumerCreditInformationStage
  nextPaymentDate: string
  periodDescription: string
}

const toCurrentFormatDate = (date: string) => {
  if (!date) {
    return date
  }

  const parse = new Date(Date.parse(date))
  return formatDate(parse, 'DD.MM.YYYY')
}

export const Period = ({ stage, nextPaymentDate, periodDescription }: PeriodProps) => {
  if (stage === ProductsModel.ConsumerCreditInformationStage.SpendingStage) {
    return (
      <PeriodWrapper>
        <PeriodTitle>{periodDescription}</PeriodTitle>
      </PeriodWrapper>
    )
  }
  if (stage === ProductsModel.ConsumerCreditInformationStage.ScheduleFormingStage) {
    return (
      <PeriodWrapper>
        <PeriodTitle>Период трат завершён</PeriodTitle>
        <PeriodDesc>Платёж будет сформирован {toCurrentFormatDate(nextPaymentDate)}</PeriodDesc>
      </PeriodWrapper>
    )
  }

  return null
}
