import React from 'react'
import { AppContext } from '@/models/AppContext'
import { makeAutoObservable } from 'mobx'
import { pinCodeVariants, pinCodeWidgetSteps } from '../PinCodeContent'

export class Store {
  private static instance: Store
  private static exists = false
  code = ''
  step: pinCodeWidgetSteps = pinCodeWidgetSteps.PIN_CODE_FORM
  variant: pinCodeVariants
  sessionUID: string
  mobilePhone: string
  closeModalHandler: () => void
  modalOpened = false

  constructor(private context: AppContext) {
    if (Store.exists) {
      return Store.instance
    }
    Store.instance = this

    makeAutoObservable(this, {}, { autoBind: true })

    context.eventBus.pinCodeModal.open.on((props) => {
      if (props.mobilePhone) {
        this.mobilePhone = props.mobilePhone
      }
      this.variant = props.variant
      this.sessionUID = props.sessionUID
      this.open()
    })

    context.eventBus.pinCodeModal.close.on(() => {
      this.close()
    })

    context.eventBus.pinCodeModal.reset.on(() => {
      this.reset()
    })
  }

  reset() {
    this.code = ''
    this.step = pinCodeWidgetSteps.PIN_CODE_FORM
  }

  open() {
    this.modalOpened = true
  }

  close() {
    this.modalOpened = false
    if (this.closeModalHandler) {
      this.closeModalHandler()
    }
    this.reset()
  }

  get goBackAvailable() {
    return this.step !== pinCodeWidgetSteps.PIN_CODE_FORM
  }

  goBack() {
    this.reset()
  }

  setCode(value) {
    this.code = value
  }

  get isInitialized() {
    return this.sessionUID && this.variant
  }
}
