import { ResponseType } from '@/models/ResponseType'
import { createSelector } from 'reselect'
import { ProductsModel, useProductsQuery } from '@/api/products/products'
import { useCardsMcpQuery } from '@/api/products/cardsMcp'
import { UseQueryResult } from 'react-query'
import { SystemError } from '@/models/SystemError'
import { AlienCardsModel, useAlienCardsQuery } from '@/api/products/alienCards'
import { ApiStatus, useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import _keyBy from 'lodash/keyBy'

type State = {
  debitCards: UseQueryResult<ProductsModel.ResponseData, ProductsModel.ErrorResponse>
  creditCards: UseQueryResult<ProductsModel.ResponseData, ProductsModel.ErrorResponse>
  alienCards: UseQueryResult<AlienCardsModel.ResponseData, AlienCardsModel.ErrorResponse>
}
const selector = createSelector(
  (state: State) => state.debitCards.data,
  (state: State) => state.debitCards.status,
  (state: State) => state.debitCards.refetch,
  (state: State) => state.creditCards.data,
  (state: State) => state.creditCards.status,
  (state: State) => state.creditCards.refetch,
  (state: State) => state.alienCards.data,
  (state: State) => state.alienCards.status,
  (state: State) => state.alienCards.refetch,
  (
    debitCardsResponse,
    debitCardsStatus,
    debitCardsRefetch,
    creditCardsResponse,
    creditCardsStatus,
    creditCardsRefetch,
    alienCardsResponse,
    alienCardsStatus,
    alienCardsRefetch
  ): {
    error: SystemError<'NETWORK' | 'PARTIAL_RESPONSE' | 'IS_EMPTY_PRODUCTS'> | null
    productIds: string[]
    products: Record<
      string,
      ProductsModel.DebitCard | ProductsModel.CreditCard | AlienCardsModel.AlienCard
    >
    status: ApiStatus
    retry: () => void
    hasAnyResult: boolean
  } => {
    const retryFns: (() => void)[] = []

    let products: (
      | ProductsModel.DebitCard
      | ProductsModel.CreditCard
      | AlienCardsModel.AlienCard
    )[] = []

    let hasNetworkError = false
    let hasPartialResponse = false
    let status = ApiStatus.Idle

    if (
      debitCardsStatus === 'success' ||
      debitCardsStatus === 'error' ||
      creditCardsStatus === 'success' ||
      creditCardsStatus === 'error' ||
      alienCardsStatus === 'success' ||
      alienCardsStatus === 'error'
    ) {
      status = ApiStatus.Fulfilled
    }

    if (
      debitCardsStatus === 'error' &&
      creditCardsStatus === 'error' &&
      alienCardsStatus === 'error'
    ) {
      status = ApiStatus.Rejected
    }

    if (
      debitCardsStatus === 'loading' ||
      creditCardsStatus === 'loading' ||
      alienCardsStatus === 'loading'
    ) {
      status = ApiStatus.Pending
    }

    if (
      debitCardsStatus === 'error' ||
      creditCardsStatus === 'error' ||
      alienCardsStatus === 'error'
    ) {
      hasNetworkError = true
    }

    if (debitCardsStatus === 'error') {
      retryFns.push(debitCardsRefetch)
    }

    if (creditCardsStatus === 'error') {
      retryFns.push(creditCardsRefetch)
    }

    if (alienCardsStatus === 'error') {
      retryFns.push(alienCardsRefetch)
    }

    if (creditCardsResponse) {
      if (
        creditCardsResponse.type === ResponseType.ResolveWithData ||
        creditCardsResponse.type === ResponseType.ResolveWithDataAndSystemError
      ) {
        const creditCards = creditCardsResponse.products.filter(
          (product) => product.productType === ProductsModel.ProductType.CreditCard
        )

        products = products.concat(...creditCards)
      } else if (creditCardsResponse.type !== ResponseType.ResolveWithSystemError) {
        hasPartialResponse = true
        retryFns.push(creditCardsRefetch)
      }
    }

    if (debitCardsResponse) {
      if (debitCardsResponse.type === ResponseType.ResolveWithData) {
        products = products.concat(
          ...debitCardsResponse.products.filter(
            (product) => product.cardStatusDisplayed !== ProductsModel.CardStatusDisplayed.Unknown
          )
        )
      } else {
        hasPartialResponse = true
        retryFns.push(debitCardsRefetch)
      }
    }

    if (alienCardsResponse) {
      if (alienCardsResponse.type === ResponseType.ResolveWithData) {
        products = products.concat(...alienCardsResponse.products)
      } else {
        hasPartialResponse = true
        retryFns.push(alienCardsRefetch)
      }
    }

    const error: SystemError<'NETWORK' | 'PARTIAL_RESPONSE' | 'IS_EMPTY_PRODUCTS'> | null =
      hasNetworkError
        ? {
            type: 'NETWORK',
            message: 'При получении данных произошла ошибка',
          }
        : hasPartialResponse
        ? {
            type: 'PARTIAL_RESPONSE',
            message: 'Ведутся сервисные работы',
          }
        : status === ApiStatus.Fulfilled && !products.length
        ? {
            type: 'IS_EMPTY_PRODUCTS',
            message: 'Список продуктов пуст',
          }
        : null

    const hasProducts = products.length > 0
    const productsMap = _keyBy(products, 'id')

    return {
      error: error,
      products: productsMap,
      productIds: products.map((item) => item.id),
      status,
      retry: () => {
        retryFns.forEach((item) => {
          item()
        })
      },
      hasAnyResult: hasProducts || !!error,
    }
  }
)

export function useGetSliderProductsSelector() {
  const context = useAppContext<AppContext>()
  const debitCards = useCardsMcpQuery()

  const creditCards = useProductsQuery({
    apiVersion: context.config.apiVersion,
  })

  const alienCards = useAlienCardsQuery()

  return selector({ debitCards: debitCards, creditCards: creditCards, alienCards: alienCards })
}
