import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import React, { createContext } from 'react'
import { Store } from './Store'

export const StoreContext = createContext<Store>(null)

export const StoreProvider: React.FC = ({ children }) => {
  const store = useInitializeStore()

  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}

let store: Store
function useInitializeStore() {
  const context = useAppContext<AppContext>()

  const _store = store ?? new Store(context)

  // Create the store once in the client
  if (!store) {
    store = _store
  }

  return _store
}
