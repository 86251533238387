import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { SmsCodeStep } from './components/sms-code-step'
import { PinCodeStep } from './components/pin-code-step'
import { pinCodeVariants, pinCodeWidgetSteps } from './PinCodeContent'
import { StoreProvider, useStore } from './store'
import { DialogError } from '@/components/DialogError'
import { PinSuccessStep } from './components/pin-success-step'
import { PinCodeModalWrapper } from './PinCodeModalWrapper'

export interface PinCodeModalProps {
  mobilePhone?: string
  sessionUID?: string
  variant?: pinCodeVariants
  closeModalHandler?: () => void
  openOnlyWidget?: boolean
}

const Root = observer<PinCodeModalProps>((props) => {
  const widgetStore = useStore()
  useEffect(() => {
    if (props.sessionUID) {
      widgetStore.sessionUID = props.sessionUID
    }
    if (props.mobilePhone) {
      widgetStore.mobilePhone = props.mobilePhone
    }
    if (props.variant) {
      widgetStore.variant = props.variant
    }
    if (props.closeModalHandler) {
      widgetStore.closeModalHandler = props.closeModalHandler
    }
  }, [props])

  const widgetContent = (
    <>
      {widgetStore.step === pinCodeWidgetSteps.PIN_CODE_FORM && <PinCodeStep />}
      {widgetStore.step === pinCodeWidgetSteps.SMS_CODE_FORM && <SmsCodeStep />}
      {widgetStore.step === pinCodeWidgetSteps.SMS_CODE_ERROR && (
        <DialogError goBack={widgetStore.close} />
      )}
      {widgetStore.step === pinCodeWidgetSteps.SMS_CODE_SUCCESS && <PinSuccessStep />}
    </>
  )

  return (
    <>
      {widgetStore.isInitialized && (
        <PinCodeModalWrapper openOnlyWidget={props.openOnlyWidget}>
          {widgetContent}
        </PinCodeModalWrapper>
      )}
    </>
  )
})

export const PinCodeModal = observer<PinCodeModalProps>((props) => {
  return (
    <StoreProvider>
      <Root {...props} />
    </StoreProvider>
  )
})
