import React from 'react'
import { Headline16 } from '@platform-ui/typography'
import {
  TabListDialogMoreWrapper,
  TabListDialogMoreIconButton,
  TabListDialogMoreTextWrapper,
  TabListDialogMoreDesc,
  TabListDialogMoreRightIcon,
} from './styled'

export type DialogMoreProps = {
  data: {
    id: string
    title: string
    description: string
    icon: React.ReactNode
    parentTabId: string
  }[]
  setActiveTabId: (id: string) => void
}

export const DialogMore = ({ data, setActiveTabId }: DialogMoreProps) => {
  return (
    <>
      {data.map(({ id, icon, title, description }) => {
        return (
          <TabListDialogMoreWrapper key={id} onClick={() => setActiveTabId(id)}>
            <TabListDialogMoreIconButton>{icon}</TabListDialogMoreIconButton>
            <TabListDialogMoreTextWrapper>
              <Headline16>{title}</Headline16>
              <TabListDialogMoreDesc>{description}</TabListDialogMoreDesc>
            </TabListDialogMoreTextWrapper>
            <TabListDialogMoreRightIcon color="icons/main" />
          </TabListDialogMoreWrapper>
        )
      })}
    </>
  )
}
