import { HttpClientModel } from '@/core'
import { ResponseType } from '@/models/ResponseType'
import { ResolveWithSystemErrorResponse } from '@/models/ResolveWithSystemErrorResponse'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'

export namespace PaymentsScheduleModel {
  export type Func = (props: Props) => Promise<Response>
  export interface Props {
    contractNumber: string
    apiVersion: string
  }

  export type Response = HttpClientModel.Response<ResponseData>
  export type ErrorResponse = HttpClientModel.ErrorResponse<ErrorResponseData>
  export interface ResolveWithDataResponse {
    type: ResponseType.ResolveWithData
    scheduleList: ScheduleList[]
    isExpiredData: boolean
  }

  export interface ScheduleList {
    paymentNum: string
    dueDate: string
    paymentAmount: number
    paymentStatus: ScheduleListStatus
  }

  export enum ScheduleListStatus {
    NotSet,
    Paid,
    UnPaid,
    MonthlyPayment,
    Future,
  }

  export type ResponseData = ResolveWithDataResponse | ResolveWithSystemErrorResponse
  export type ErrorResponseData = RejectedWithSystemErrorResponse
  export type RequestData = {
    contractNumber: string
  }
  export interface RequestHeaders {
    _ver_: string
    _os_: string
  }
}
