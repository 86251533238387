import { useQuery, UseQueryOptions } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { stringTemplate } from '@/utils/string-template'
import { adapter } from './adapters/adapter'
import { CovenantInfoModel } from './CovenantInfoModel'

export const covenantInfo: CovenantInfoModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.products.covenantInfo,
    {} as ApiManagerModel.ApiOption
  )
  const response = await HttpClient.get<
    CovenantInfoModel.ResponseData,
    void,
    CovenantInfoModel.RequestHeaders,
    CovenantInfoModel.ErrorResponse
  >({
    url: stringTemplate<{ contractNumber: string; forceUpdate: boolean }>(path, {
      contractNumber: props.contractNumber,
      forceUpdate: true,
    }),
    headers: {
      _ver_: props.apiVersion,
      _os_: '4',
    },
    responseAdapter: adapter,
    control,
  })

  return response
}

function covenantInfoKey(props: { contractNumber: string }) {
  return ['v1/api/Cards/CovenantInfo', props.contractNumber]
}

export function useCovenantInfoQuery<T = CovenantInfoModel.ResponseData>(
  props: CovenantInfoModel.Props,
  options?: Omit<
    UseQueryOptions<CovenantInfoModel.ResponseData, CovenantInfoModel.ErrorResponse, T>,
    'queryKey' | 'queryFn'
  >
) {
  const key = covenantInfoKey(props)
  const result = useQuery(key, async () => covenantInfo(props).then((res) => res.data), options)

  return result
}
