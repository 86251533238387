import styled from '@emotion/styled'
import { Body14 } from '@platform-ui/typography'

export const DialogSuccessWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  height: '100%',
  paddingBottom: 50,

  [`${theme.media.md}`]: {
    paddingBottom: 0,
  },
}))

export const TextWrapper = styled.div(() => ({
  margin: '116px auto',
  maxWidth: 343,
  textAlign: 'center',
  padding: '0 24px',
}))

export const TextStyled = styled(Body14)(({ theme }) => ({
  color: theme.color['text/secondary'],
  fontSize: 14,
  lineHeight: '18px',
  marginTop: 12,
}))

export const ImageStyled = styled.div(() => ({
  padding: '20px 0',
}))
