import React, { useState } from 'react'
import { ServicesIcon } from '@platform-ui/icons/ServicesIcon'
import { DepositSecondaryIcon } from '@platform-ui/icons/DepositSecondaryIcon'
import { MoreIcon } from '@platform-ui/icons/MoreIcon'
import { DocsIcon } from '@platform-ui/icons/DocsIcon'
import { FreezeIcon } from '@platform-ui/icons/FreezeIcon'
import { MenuOffersTransactionsIcon } from '@platform-ui/icons/MenuOffersTransactionsIcon'
import { PincodeIcon } from '@platform-ui/icons/PincodeIcon'
import { TabList } from '@/components/TabList'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { observer } from 'mobx-react-lite'
import { ProductsModel } from '@/api/products/products'
import { CurrencyType } from '@/models/CurrencyType'
import { DebitDialogContent } from './DebitDialogContent'
import { TRANSACTION_TYPE } from '@/features/transfers-list/constants'

export enum TabListId {
  Deposit = 'deposit',
  Pay = 'pay',
  More = 'more',
}

export enum TabListMoreId {
  BankDetails = 'bank details',
  CardDetails = 'card details',
  PinCodeChange = 'pin-code change',
  CardBlocking = 'card blocking',
  CardUnlock = 'card unlock',
}

export const getTabList = (isMobile: boolean) => {
  const tabList: {
    id: string
    title: string
    icon: React.ReactNode
    parentTabId: string
  }[] = [
    {
      id: TabListId.Deposit,
      title: 'Пополнить',
      icon: <DepositSecondaryIcon color="icons/primary" size={isMobile ? 's12' : 's16'} />,
      parentTabId: '',
    },
    {
      id: TabListId.Pay,
      title: 'Оплатить',
      icon: <ServicesIcon color="icons/primary" size={isMobile ? 's12' : 's16'} />,
      parentTabId: '',
    },
    {
      id: TabListId.More,
      title: 'Ещё',
      icon: <MoreIcon color="icons/primary" size={isMobile ? 's12' : 's16'} />,
      parentTabId: '',
    },
  ]

  return {
    tabList,
  }
}

export const getTabListMore = () => {
  const tabListMore: {
    id: string
    title: string
    description: string
    icon: React.ReactNode
    parentTabId: string
  }[] = [
    {
      id: TabListMoreId.BankDetails,
      title: 'Реквизиты счёта',
      description: 'Номер счёта, БИК, ИНН и другие банковские реквизиты',
      icon: <DocsIcon color="icons/primary" />,
      parentTabId: TabListId.More,
    },
    {
      id: TabListMoreId.CardDetails,
      title: 'Реквизиты карты',
      description: 'Номер карты, срок действия и CVV2',
      icon: <MenuOffersTransactionsIcon color="icons/primary" />,
      parentTabId: TabListId.More,
    },
    {
      id: TabListMoreId.PinCodeChange,
      title: 'Смена ПИН-кода',
      description: 'Создать новый ПИН-код для карты',
      icon: <PincodeIcon color="icons/primary" />,
      parentTabId: TabListId.More,
    },
    {
      id: TabListMoreId.CardBlocking,
      title: 'Заблокировать карту',
      description: 'Запретить все операции по карте',
      icon: <FreezeIcon color="icons/primary" />,
      parentTabId: TabListId.More,
    },
    {
      id: TabListMoreId.CardUnlock,
      title: 'Разблокировать карту',
      description: 'Разрешить все операции по карте',
      icon: <FreezeIcon color="icons/primary" />,
      parentTabId: TabListId.More,
    },
  ]
  return { tabListMore }
}

const getActiveTab = (
  activeTabId: string,
  tabList: {
    id: string
    title: string
    icon: React.ReactNode
    parentTabId: string
  }[],
  tabListMore: {
    id: string
    title: string
    description: string
    icon: React.ReactNode
    parentTabId: string
  }[]
) => [...tabList, ...tabListMore].find(({ id }) => id === activeTabId)

export interface TabBarProps {
  maskCardNumber: string
  contractNumber: string
  cardMBR: number
  cardStatusDisplayed: ProductsModel.CardStatusDisplayed
  isPlasticActivationAvailable: boolean
  currencyType: CurrencyType
  accountNumber: string
  productId: string
  cardRole: ProductsModel.CardRole
}

export const TabBar = observer(
  ({
    maskCardNumber,
    contractNumber,
    cardMBR,
    cardStatusDisplayed,
    isPlasticActivationAvailable,
    currencyType,
    accountNumber,
    productId,
    cardRole,
  }: TabBarProps) => {
    const { config, eventBus } = useAppContext<AppContext>()
    const isMobile = config.device.isMobile
    const isHideDevelopmentInProgress = config.features.hideDevelopmentInProgress

    const [isOpenModal, setIsOpenModal] = useState(false)
    const [activeTabId, setActiveTabId] = useState('')

    const { tabList } = getTabList(isMobile)
    const { tabListMore } = getTabListMore()

    const activeTab = getActiveTab(activeTabId, tabList, tabListMore)
    const title = activeTab ? activeTab.title : ''

    const onOpen = (id: string) => () => {
      if (id === TabListId.Deposit) {
        eventBus.transfersModal.open.emit({
          productId,
          type: TRANSACTION_TYPE.betweenAccounts,
          isHaveInitTo: true,
        })
        return
      }

      if (id === TabListId.Pay) {
        eventBus.payListModal.open.emit({ id: productId })
        return
      }

      setActiveTabId(id)
      if (id === TabListId.More) {
        setIsOpenModal(true)
      }
    }

    const onClose = () => {
      setActiveTabId('')
      setIsOpenModal(false)
      eventBus.pinCodeModal.reset.emit()
    }

    const goBack = () => {
      if (activeTab.parentTabId) {
        setActiveTabId(activeTab.parentTabId)
      }
    }

    return (
      <>
        <TabList data={tabList} onOpen={onOpen} />
        <DebitDialogContent
          title={title}
          activeTabId={activeTabId}
          setActiveTabId={setActiveTabId}
          onClose={onClose}
          goBack={goBack}
          cardStatusDisplayed={cardStatusDisplayed}
          isPlasticActivationAvailable={isPlasticActivationAvailable}
          maskCardNumber={maskCardNumber}
          contractNumber={contractNumber}
          cardMBR={cardMBR}
          currencyType={currencyType}
          accountNumber={accountNumber}
          tabListMore={tabListMore}
          cardRole={cardRole}
          isOpen={isOpenModal}
        />
      </>
    )
  }
)
