import { BaseModal } from '@platform-ui/components'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useStore } from './store'
import { pinCodeContent } from './PinCodeContent'

export interface PinCodeModalWrapperProps {
  openOnlyWidget?: boolean
  children?: React.ReactNode
}

export const PinCodeModalWrapper = observer<PinCodeModalWrapperProps>(
  ({ openOnlyWidget, children }) => {
    const widgetStore = useStore()
    if (openOnlyWidget) {
      return <>{children}</>
    } else {
      return (
        <BaseModal
          title={widgetStore.variant ? pinCodeContent[widgetStore.variant].title : ''}
          isOpen={widgetStore.modalOpened}
          onClose={widgetStore.close}
          isBackBtn={widgetStore.goBackAvailable}
          backBtnClick={widgetStore.goBack}
        >
          {children}
        </BaseModal>
      )
    }
  }
)
