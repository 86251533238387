import { useState } from 'react'
import { SplitedCodeInputsProps } from '@platform-ui/components/SplitedCodeInputs'
import { useCheckSmsMutation, CheckSmsModel } from '@/api/digital-card/checkSms'
import { useSMSCodeStore } from './useSMSCodeStore'
import { useResendSmsMutation } from '@/api/digital-card/resendSms'
import { ResponseType } from '@/models/ResponseType'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'

export const useSmsCodeState = (requestId) => {
  const [codeValue, setCodeValue] = useState('')
  const [splitedCodeStatus, setSplitedCodeStatus] =
    useState<SplitedCodeInputsProps['status']>('default')
  const context = useAppContext<AppContext>()

  const timerStore = useSMSCodeStore()

  const [checkSmsResponse, setCheckSmsResponse] = useState({
    resultCode: '',
    resultText: '',
    resendNum: null,
    verifyNum: null,
  })

  const [mismatchResultText, setMismatchResultText] = useState('')
  const [checkSmsBlockedMessage, setCheckSmsBlockedMessage] = useState('')

  const handleChangeSplitedCode = (value: string) => {
    setSplitedCodeStatus('default')
    setCodeValue(value)

    /**
     * Если остался текст mismatch, то убрать его после ввода смс
     */
    if (mismatchResultText) {
      setMismatchResultText('')
    }
  }

  const checkSmsMutation = useCheckSmsMutation()

  const submitCheck = async () => {
    setSplitedCodeStatus('success')

    let checkSmsMutationResponse: CheckSmsModel.Response
    try {
      checkSmsMutationResponse = await checkSmsMutation.mutateAsync({
        otp: codeValue,
        requestId,
        apiVersion: context.config.apiVersion,
      })
    } catch (error) {
      handleBlockedResult(error.response)
      console.log('error', error)
    }
    if (checkSmsMutationResponse?.data.type === ResponseType.ResolveWithData) {
      if (checkSmsMutationResponse?.data?.result.resultCode === CheckSmsModel.ResultCode.Match) {
        setCheckSmsResponse(checkSmsMutationResponse.data.result)
      }

      /**
       * Отобразить ошибку "код введен не верно"
       */
      if (checkSmsMutationResponse?.data?.result.resultCode === CheckSmsModel.ResultCode.Mismatch) {
        setSplitedCodeStatus('error')
        setCodeValue('')
        setMismatchResultText(checkSmsMutationResponse.data.result.resultText)
      }

      /**
       * Отобразить ошибку "клиент заблокирован"
       */
      handleBlockedResult(checkSmsMutationResponse.data)
    }
  }

  const handleFulfilledSplitedCode = () => {
    submitCheck()
  }

  const handleBlockedResult = (responseData?: CheckSmsModel.ResolveWithDataResponse) => {
    if (responseData?.result?.resultCode === CheckSmsModel.ResultCode.Blocked) {
      setCheckSmsBlockedMessage(responseData.result.resultText)
    }
  }

  const resendSmsMutation = useResendSmsMutation()

  const handleClick = async () => {
    /**
     * Уменьшение кол-ва попыток отправки кода
     */
    timerStore.decrementAttempt()

    try {
      await resendSmsMutation.mutateAsync({
        requestId,
        apiVersion: context.config.apiVersion,
      })
    } catch (error) {
      console.log('error', error)
    }
  }

  return {
    codeValue,
    splitedCodeStatus,
    handleChangeSplitedCode,
    handleFulfilledSplitedCode,
    timerStore,
    handleClick,
    checkSmsResponse,
    status: checkSmsMutation.status,
    mismatchResultText,
    checkSmsBlockedMessage,
  }
}
