import React from 'react'
import { Headline20 } from '@platform-ui/typography'
import { Button } from '@platform-ui/components/Button'
import { TextWrapper, TextStyled, DialogSuccessWrapper, ImageStyled } from './styled'
import { pinCodeContent } from '../../PinCodeContent'
import Image from '@/next//image'
import Success from '@/assets/Success.png'
import { useStore } from '../../store'
import styled from '@emotion/styled'

export const ButtonWrapper = styled.div(() => ({
  position: 'fixed',
  padding: '32px 16px',
  width: '100%',
  left: 0,
  bottom: 0,
}))

export const PinSuccessStep = () => {
  const widgetStore = useStore()
  return (
    <DialogSuccessWrapper>
      <TextWrapper>
        <ImageStyled>
          <Image src={Success} width={300} height={152} alt="Success" />
        </ImageStyled>
        <Headline20>{pinCodeContent[widgetStore.variant].pages.finalSuccess.title}</Headline20>
        <TextStyled>{pinCodeContent[widgetStore.variant].pages.finalSuccess.hint}</TextStyled>
      </TextWrapper>

      <ButtonWrapper>
        <Button isFullWidth onClick={widgetStore.close}>
          Закрыть
        </Button>
      </ButtonWrapper>
    </DialogSuccessWrapper>
  )
}
