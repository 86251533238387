import React from 'react'
import { Headline16 } from '@platform-ui/typography'
import {
  DialogBankDetailsCellWrapper,
  DialogBankDetailsCell,
  DialogBankDetailsTitle,
  DialogBankDetailsButton,
  ButtonStyled,
} from './styled'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { CurrencyType } from '@/models/CurrencyType'
import { handleShare } from '@/utils/handleShare'

export type GetInfoInterbankTransferDataProps = {
  clientFio: string
  corrAcc: string
  bankName: string
  bankNum: string
  bankINN: string
  bankSwift: string
  corrBankSwift: string
  corrBankName: string
  currencyType: CurrencyType
  accountNumber: string
}

export enum DialogBankDetailsId {
  Swift = 'swift',
  Inn = 'inn',
  Bik = 'bik',
  CorrBankName = 'corrBankName',
  CorrBankSwift = 'corrBankSwift',
}

export const DialogBankDetailsSuccess = ({
  clientFio,
  bankName,
  corrAcc,
  bankNum,
  bankINN,
  bankSwift,
  corrBankSwift,
  corrBankName,
  currencyType,
  accountNumber,
}: GetInfoInterbankTransferDataProps) => {
  const arr: {
    id?: string
    title: string
    description: string
  }[] = [
    {
      title: 'Получатель',
      description: clientFio,
    },
    {
      title: 'Счёт',
      description: accountNumber,
    },
    {
      title: 'Банк получателя',
      description: bankName,
    },
    {
      title: 'Корреспондентский счёт',
      description: corrAcc,
    },
    {
      id: DialogBankDetailsId.Bik,
      title: 'БИК',
      description: bankNum,
    },
    {
      id: DialogBankDetailsId.Inn,
      title: 'ИНН',
      description: bankINN,
    },
    {
      id: DialogBankDetailsId.Swift,
      title: 'SWIFT',
      description: bankSwift,
    },
    {
      id: DialogBankDetailsId.CorrBankName,
      title: 'Банк-корреспондент',
      description: corrBankName,
    },
    {
      id: DialogBankDetailsId.CorrBankSwift,
      title: 'SWIFT банка-корреспондента',
      description: corrBankSwift,
    },
  ]

  const context = useAppContext<AppContext>()

  const filteredData = arr.filter(({ id }) => {
    /**
     * Если счёт в валюте, то скрыть поля "БИК" и "ИНН"
     */
    if (currencyType === 'USD' || currencyType === 'EUR')
      return id !== DialogBankDetailsId.Bik && id !== DialogBankDetailsId.Inn
    /**
     * Если счёт рублёвый, то скрыть поля "SWIFT", "Банк-корреспондент" и "SWIFT Банк-корреспондента"
     */
    if (currencyType === 'RUB')
      return (
        id !== DialogBankDetailsId.Swift &&
        id !== DialogBankDetailsId.CorrBankName &&
        id !== DialogBankDetailsId.CorrBankSwift
      )
    return true
  })

  const getBankDetailsInfo = filteredData.reduce((prev, curr) => {
    prev = `${prev}\n${curr.title}\n${curr.description}`
    return prev.trim()
  }, '')

  return (
    <>
      <DialogBankDetailsCellWrapper>
        {filteredData.map((item) => {
          return (
            <DialogBankDetailsCell key={item.title}>
              <DialogBankDetailsTitle>{item.title}</DialogBankDetailsTitle>
              <Headline16>{item.description}</Headline16>
            </DialogBankDetailsCell>
          )
        })}
      </DialogBankDetailsCellWrapper>
      <DialogBankDetailsButton>
        <ButtonStyled isFullWidth onClick={() => handleShare(getBankDetailsInfo, context)}>
          Поделиться
        </ButtonStyled>
      </DialogBankDetailsButton>
    </>
  )
}
