import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { HttpClient } from '@/core'
import { AdapterModel } from './AdapterModel'

export const adapter: AdapterModel.Func = (data) => {
  const errorData = data as AdapterModel.ResponseDataError
  /**
   * Сервер всегда возвращает status=200 в заголовке
   * Реальный статус приходит в теле ответа
   * 401 или 500+ считаем rejected ответами с наличием критической ошибки
   */
  if (errorData?.StatusCode === 401 || errorData?.StatusCode >= 500) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: errorData.ErrorDetails
        ? errorData.ErrorDetails.map(({ Error }) => ({
            type: Error,
            message: Error,
          }))
        : [],
    }
    throw HttpClient.createError(errorData?.StatusCode, exception)
  }

  const systemErrorData = data as AdapterModel.ResponseDataSystemError

  if (systemErrorData?.error && systemErrorData?.status) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [
        {
          message: 'Произошла ошибка на стороне сервера',
          type: 'INTERNAL_SERVER_ERROR',
        },
      ],
    }
    throw HttpClient.createError(systemErrorData.status, exception)
  }

  try {
    const successData = data as AdapterModel.ResponseDataSuccess
    const product = { ...successData, lastFourCardDigits: successData.maskCardNumber.slice(-4) }
    if (!successData?.cardMbr) {
      product.cardMbr = 0
    }
    return {
      type: ResponseType.ResolveWithData,
      product,
    }
  } catch (error) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [{ message: 'Ошибка при обработке полученных данных', type: 'ADAPTER_ERROR' }],
    }

    throw HttpClient.createError(500, exception, error)
  }
}
