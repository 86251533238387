import { useQuery, UseQueryOptions } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { CreditCardsModel } from './CreditCardsModel'
import { adapter } from './adapters/adapter'
import { stringTemplate } from '@/utils/string-template'

export const creditCard: CreditCardsModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.products.getCreditCard,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.get<
    CreditCardsModel.ResponseData,
    any,
    CreditCardsModel.RequestHeaders,
    CreditCardsModel.ErrorResponse
  >({
    url: stringTemplate<{ maskCardNumber: string; mbr: number }>(path, {
      maskCardNumber: props.maskCardNumber,
      mbr: props.cardMbr || 0,
    }),
    headers: null,
    responseAdapter: adapter,
    control,
  })

  return response
}

function creditCardsKey(props: { maskCardNumber: string }) {
  return ['/api/products/getCreditCard', props.maskCardNumber]
}

export function useCreditCardsQuery<
  T extends CreditCardsModel.ResponseData = CreditCardsModel.ResponseData
>(
  props: CreditCardsModel.Props,
  options: Omit<
    UseQueryOptions<CreditCardsModel.ResponseData, CreditCardsModel.ErrorResponse, T>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const key = creditCardsKey(props)
  const result = useQuery(key, (state) => creditCard({ ...props }).then((res) => res.response), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    ...options,
  })

  return result
}
