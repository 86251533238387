import React from 'react'
import styled from '@emotion/styled'
import { Headline14, Headline20 } from '@platform-ui/typography'
import { Button } from '@platform-ui/components/Button'
import { Currency } from '@/components/Currency'

export const DepositWrapper = styled.div(({ theme }) => ({
  background: theme.color['surface/secondary'],
  borderRadius: 12,
  padding: 12,
  margin: '16px 0',

  [`${theme.media.md}`]: {
    padding: 20,
  },
}))

export const DepositTitle = styled(Headline20)(({ theme }) => ({
  margin: '4px 0 12px',

  [`${theme.media.md}`]: {
    margin: '4px 0 16px',
  },
}))

export const DepositDesc = styled(Headline14)(({ theme }) => ({
  color: theme.color['text/secondary'],
  fontSize: 12,
}))

export interface DepositProps {
  amount: number
  desc: string
  buttonText: string
  onClick: () => void
}

export const Deposit = ({ amount, desc, buttonText, onClick }: DepositProps) => {
  return (
    <DepositWrapper>
      <DepositDesc>{desc}</DepositDesc>
      <DepositTitle>
        <Currency amount={amount} />
      </DepositTitle>
      <Button onClick={onClick} isFullWidth size="m">
        {buttonText}
      </Button>
    </DepositWrapper>
  )
}
