import React from 'react'
import styled from '@emotion/styled'
import { Body16, Headline20 } from '@platform-ui/typography'

export const PeriodWrapper = styled.div(({ theme }) => ({
  background: theme.color['surface/secondary'],
  borderRadius: 12,
  padding: 12,
  margin: '16px 0',

  [`${theme.media.md}`]: {
    padding: 20,
  },
}))

export const PeriodTitle = styled(Headline20)(() => ({
  fontSize: 16,
  fontWeight: 600,
}))

export const PeriodDesc = styled(Body16)(({ theme }) => ({
  fontSize: 14,
  lineHeight: '18px',
  color: theme.color['text/secondary'],
  marginTop: 4,

  [`${theme.media.md}`]: {
    marginTop: 8,
  },
}))
