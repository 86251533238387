import React, { useEffect } from 'react'
import { useGetCreditCardProductById } from './selectors/useGetCreditCardProductById'
import { ApiStatus } from '@/core'
import { TabBarShimmer } from './components/TabBarShimmer'
import { PaymentDate } from './components/PaymentDate'
import { History } from '@/features/history'
import { color } from '@platform-ui/core'
import { HistoryShimmer } from '../history/components/HistoryShimmer'
import { CashbackWidget } from './styled'
import { TabBar } from '@/features/credit-card/containers/TabBar'
import { useSettingsQuery } from '@/api/settings/settings'
import { ResponseType } from '@/models/ResponseType'
import { usePolzaCards } from '../product-lists/hooks/usePolzaCards'
import { ProductsModel } from '@/api/products/products'
import { ClientProductsModel } from '@/api/products/clientProducts'
import { CardLockTile } from '@/features/card-lock-tile'
import { useAppContext } from '../../core'
import { AppContext } from '../../models/AppContext'
import { observer } from 'mobx-react-lite'

export interface CreditDetailProps {
  product: ProductsModel.CreditCard
  productId: string
  maskCardNumber: string
  cardMBR: number
  cardStatus: ProductsModel.CardStatusDisplayed
  cardType: ClientProductsModel.CardType
  contractStatus: number
}

export const CreditCardDetail = observer(
  ({
    maskCardNumber,
    productId,
    product,
    cardMBR,
    cardStatus,
    cardType,
    contractStatus,
  }: CreditDetailProps) => {
    const { status, productById } = useGetCreditCardProductById(maskCardNumber, cardMBR)
    const { creditCardsPolza } = usePolzaCards()
    const { data: settingsData } = useSettingsQuery()
    const { popups, eventBus } = useAppContext<AppContext>()

    useEffect(() => {
      if (popups.screens?.creditCard.length > 0) {
        eventBus.popupModal.open.emit({
          popup: popups.screens.creditCard[0],
        })
      }
    }, [popups])

    if (status === ApiStatus.Pending) {
      return (
        <>
          <TabBarShimmer />
          <HistoryShimmer />
        </>
      )
    }

    const isCreditCardPolza =
      creditCardsPolza.length &&
      creditCardsPolza.some(
        (item) =>
          item.productType === ProductsModel.ProductType.CreditCard &&
          item.maskCardNumber === productById?.maskCardNumber &&
          item.contractNumber === productById?.contractNumber
      )

    const isCashbackEnabled =
      settingsData?.type === ResponseType.ResolveWithData &&
      settingsData.result.enablePolzaMainWidget &&
      isCreditCardPolza

    if (productById) {
      const { contractNumber, productName, accountNumber, lastFourCardDigits } = productById

      return (
        <>
          {cardStatus === ProductsModel.CardStatusDisplayed.Blocked && (
            <CardLockTile
              cardStatus={cardStatus}
              cardNumber={maskCardNumber}
              cardMBR={cardMBR}
              cardType={cardType}
            />
          )}
          <TabBar
            maskCardNumber={maskCardNumber}
            cardMBR={cardMBR}
            contractNumber={contractNumber}
            accountNumber={accountNumber}
            productId={productId}
            cardStatus={cardStatus}
            cardType={cardType}
            contractStatus={contractStatus}
          />
          {product.cardType !== ProductsModel.CardSystem.Homer && <PaymentDate product={product} />}
          {!!isCashbackEnabled && <CashbackWidget maskCardNumber={maskCardNumber} />}
          <History
            productType="creditCard"
            maskCardNumber={maskCardNumber}
            accountNumber={accountNumber}
            contractNumber={contractNumber}
            lastFourCardDigits={lastFourCardDigits}
            productName={productName}
            startContractDate="2021-02-01"
            bgColor={color['products/savingsNew']}
            currencyType="RUB"
          />
        </>
      )
    }

    return null
  }
)
