import styled from '@emotion/styled'
import { CashbackMainWidget } from '../partners/containers/CashbackMainWidget'

export const CashbackWidget = styled(CashbackMainWidget)(({ theme }) => ({
  marginTop: theme.spacing.s12,

  [`${theme.media.md}`]: {
    marginTop: theme.spacing.s20,
  },
}))
