import React from 'react'
import { PinCodeModal } from '@/features/pin-code-modal/PinCodeModal'
import { pinCodeVariants } from '@/features/pin-code-modal/PinCodeContent'

export type DialogPinCodeProps = {
  mobilePhone: string
  sessionUID: string
  closeModalHandler?: () => void
}

export const DialogPinCode = ({
  mobilePhone,
  sessionUID,
  closeModalHandler,
}: DialogPinCodeProps) => {
  return (
    <PinCodeModal
      variant={pinCodeVariants.CHANGE}
      sessionUID={sessionUID}
      mobilePhone={mobilePhone}
      closeModalHandler={closeModalHandler}
      openOnlyWidget={true}
    />
  )
}
