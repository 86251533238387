import React from 'react'
import { useCovenantInfoQuery } from '@/api/products/covenantInfo'
import { ResponseType } from '@/models/ResponseType'
import { Button } from '@platform-ui/components/Button'
import {
  ProgressBarWrapper,
  ProgressBarTitle,
  ProgressBarDesc,
  ProgressBar,
  ProgressBarInner,
  ProgressBarAmountWrapper,
  ProgressBarAmount,
  ProgressBarAmountText,
  ShimmerSubTitle,
  ShimmerTitle,
  ShimmerDesc,
  ShimmerWrapper,
  ProgressBarAmountShimmer,
  ShimmerPeriod,
} from './styled'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { ProductsModel } from '@/api/products/products'
import { Period } from '../Period'

export interface CovenantProps {
  contractNumber: string
  stage: ProductsModel.ConsumerCreditInformationStage
  nextPaymentDate: string
}

export const Covenant = ({ contractNumber, stage, nextPaymentDate }: CovenantProps) => {
  const context = useAppContext<AppContext>()
  const { data, isError, isLoading, refetch } = useCovenantInfoQuery({
    apiVersion: context.config.apiVersion,
    contractNumber: contractNumber,
  })

  if (isError) {
    return (
      <ProgressBarWrapper isError>
        <ProgressBarDesc>Не удалось загрузить информацию о процентной ставке</ProgressBarDesc>
        <Button onClick={() => refetch()} sizeSm={'m'} view="ghost">
          Попробовать снова
        </Button>
      </ProgressBarWrapper>
    )
  }
  if (isLoading) {
    return (
      <>
        <ShimmerPeriod />
        {stage === ProductsModel.ConsumerCreditInformationStage.SpendingStage && (
          <ShimmerWrapper>
            <ShimmerTitle />
            <ShimmerSubTitle shimmerWidth="143px" />
            <ShimmerSubTitle />
            <ShimmerDesc />
            <ProgressBarAmountShimmer>
              <ShimmerSubTitle shimmerWidth="127px" />
              <ShimmerSubTitle shimmerWidth="127px" />
            </ProgressBarAmountShimmer>
            <ProgressBarAmountShimmer>
              <ShimmerSubTitle shimmerWidth="143px" />
              <ShimmerSubTitle shimmerWidth="143px" />
            </ProgressBarAmountShimmer>
          </ShimmerWrapper>
        )}
      </>
    )
  }
  if (data && data.type === ResponseType.ResolveWithData) {
    return (
      <>
        <Period
          periodDescription={data.periodDescription}
          stage={stage}
          nextPaymentDate={nextPaymentDate}
        />
        {stage === ProductsModel.ConsumerCreditInformationStage.SpendingStage && (
          <ProgressBarWrapper>
            <ProgressBarTitle>{data.title}</ProgressBarTitle>
            <ProgressBarDesc>{data.description}</ProgressBarDesc>
            {(!!data.progress || data.progress === 0) && (
              <>
                <ProgressBar>
                  <ProgressBarInner completed={data.progress} />
                </ProgressBar>
              </>
            )}

            {data.conditions.map((condition, index) => {
              return (
                <ProgressBarAmountWrapper key={index}>
                  <div>
                    <ProgressBarAmount>{condition.currentValue}</ProgressBarAmount>
                    <ProgressBarAmountText>{condition.currentTitle}</ProgressBarAmountText>
                  </div>
                  <div>
                    <ProgressBarAmount>{condition.remainValue}</ProgressBarAmount>
                    <ProgressBarAmountText>{condition.remainTitle}</ProgressBarAmountText>
                  </div>
                </ProgressBarAmountWrapper>
              )
            })}
          </ProgressBarWrapper>
        )}
      </>
    )
  }

  return null
}
