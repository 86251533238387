import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { SplitedCodeInputs } from '@platform-ui/components/SplitedCodeInputs'
import { Container } from '@platform-ui/components/Container'
import { Row } from '@platform-ui/components/Row'
import { Col } from '@platform-ui/components/Col'
import { DialogError } from '@/components/DialogError'
import { useSmsCodeState } from './useSmsCodeState'
import { DialogCardDetails } from '@/features/dialog-card-details/components/DialogCardDetails'
import { DialogCardDetailsBlocked } from '@/features/dialog-card-details/components/DialogCardDetailsBlocked'
import { CheckSmsModel } from '@/api/digital-card/checkSms/CheckSmsModel'
import { MismatchText, SmsCodeDesc, ButtonWrapper } from './styled'
import { Button } from '@platform-ui/components'

export type SmsCodeProps = {
  phone: string
  requestId: string
  goBack: () => void
  blockedMessage: string
  maskCardNumber: string
}

export const SmsCode = observer(
  ({ phone, requestId, goBack, blockedMessage, maskCardNumber }: SmsCodeProps) => {
    const {
      codeValue,
      splitedCodeStatus,
      handleChangeSplitedCode,
      handleFulfilledSplitedCode,
      timerStore,
      handleClick,
      checkSmsResponse,
      status,
      mismatchResultText,
      checkSmsBlockedMessage,
    } = useSmsCodeState(requestId)

    useEffect(() => {
      timerStore.init({
        attempt: 3,
        timeWaitSec: 60,
      })

      return () => {
        timerStore.destroy()
      }
    }, [])

    if (blockedMessage || checkSmsBlockedMessage) {
      return <DialogCardDetailsBlocked message={blockedMessage || checkSmsBlockedMessage} />
    }

    if (status === 'error') {
      return <DialogError goBack={goBack} />
    }

    if (status === 'success' && checkSmsResponse?.resultCode === CheckSmsModel.ResultCode.Match) {
      const { pan, expireDate, cvv, cardHolder } = checkSmsResponse as any

      // формируем номер карты и добавляем пробел после каждого 4-го символа
      const cardNumber = `${maskCardNumber.slice(0, 6)}${pan}${maskCardNumber.slice(-4)}`
        .replace(/\s/g, '')
        .replace(/(.{4})/g, '$1 ')

      const cardDetailsData: {
        id: string
        title: string
        description: string
        isFullWidth: boolean
      }[] = [
        {
          id: '1',
          title: 'Номер карты',
          description: cardNumber,
          isFullWidth: true,
        },
        {
          id: '2',
          title: 'Срок действия',
          description: expireDate,
          isFullWidth: false,
        },
        {
          id: '3',
          title: 'CVV2',
          description: cvv,
          isFullWidth: false,
        },
        {
          id: '4',
          title: 'Имя владельца',
          description: cardHolder,
          isFullWidth: true,
        },
      ]

      return <DialogCardDetails cardDetailsData={cardDetailsData} />
    }

    return (
      <Container flexSm>
        <Row flexSm>
          <Col
            displaySm="flex"
            flexDirectionSm="column"
            alignItemsSm="center"
            justifyContentSm="center"
          >
            <SplitedCodeInputs
              length={4}
              value={codeValue}
              onChange={handleChangeSplitedCode}
              onFullfilled={handleFulfilledSplitedCode}
              isAutoFocus
              status={splitedCodeStatus}
            />

            {mismatchResultText && <MismatchText>{mismatchResultText}</MismatchText>}

            <SmsCodeDesc>
              Мы выслали код подтверждения на номер
              <br />
              {phone}
            </SmsCodeDesc>
          </Col>
        </Row>

        <ButtonWrapper>
          <Button isFullWidth isDisabled={!timerStore.isOverTimerWait} onClick={handleClick}>
            {timerStore.isOverTimerWait
              ? 'Запросить код повторно'
              : `Повторно запросить код через ${timerStore.timeWaitString}`}
          </Button>
        </ButtonWrapper>
      </Container>
    )
  }
)
