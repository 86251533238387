import React, { useState } from 'react'
import {
  TabListWrapper,
  TextPrimary,
  TextAdditional,
  TextSecondary,
  TopContent,
  BottomContent,
  DateInfoIcon,
  SignButton,
} from './styled'
import { DialogPaymentSchedule } from '@/features/home-card-details/containers/DialogPaymentSchedule'
import { usePaymentsDetalizationQuery } from '@/api/products/paymentsDetalization'
import { AboutSecurityIcon } from '@platform-ui/icons/AboutSecurityIcon'
import { ProductsModel } from '@/api/products/products'
import { formatDate } from '@/utils/formatDate'
import { ThemeColor } from '@emotion/react'
import { pluralize } from '@/utils'

const useDateInfo = (
  product: ProductsModel.CreditCard
): {
  text: string | JSX.Element
  icon?: React.ReactNode
  iconBgColor?: ThemeColor
} | null => {
  const { nextPaymentDate } = product.contract.properties
  const contractNumber = product.contractNumber
  const paymentsDetalization = usePaymentsDetalizationQuery({ contractNumber })
  const nextPaymentDateFormat = formatDate(new Date(Date.parse(nextPaymentDate)), 'D MMMM')
  const { daysLeft, debtDays } = product.creditCardTWGuiData
  const visualStyle = paymentsDetalization.data?.visualStyle

  if (product.cardType !== ProductsModel.CardSystem.TW) {
    return null
  }

  if (!nextPaymentDate && visualStyle !== 'NotOverdueDebtToday' && visualStyle !== 'OverdueDebt') {
    return null
  }

  switch (visualStyle) {
    case 'NotOverdueDebt':
    case 'NotOverdueDebtSoon':
      return {
        text: `${nextPaymentDateFormat}, через ${daysLeft} ${pluralize(daysLeft, [
          'день',
          'дня',
          'дней',
        ])}`,
      }
    case 'NotOverdueDebtToday':
      return {
        text: <>Платеж сегодня</>,
        iconBgColor: 'icons/colors/attention',
        icon: <AboutSecurityIcon color="icons/colors/attentionTransparent" size="s12" />,
      }
    case 'NoDebt':
      return {
        text: <>Дата расчёта следующего платежа {nextPaymentDateFormat}</>,
      }
    case 'OverdueDebt':
      return {
        text: (
          <>
            Платеж просрочен на {debtDays} {pluralize(debtDays, ['день', 'дня', 'дней'])}
          </>
        ),
        iconBgColor: 'icons/colors/error',
        icon: <AboutSecurityIcon color="icons/colors/attentionTransparent" size="s12" />,
      }
    default:
      return null
  }
}
interface PaymentDateProps {
  product: ProductsModel.CreditCard
}
export const PaymentDate: React.FC<PaymentDateProps> = ({ product }) => {
  const dateInfo = useDateInfo(product)
  const [isOpenedSchedule, setIsOpenedSchedule] = useState(false)

  return (
    <>
      {dateInfo && (
        <TabListWrapper>
          <TopContent>
            <TextPrimary>Платёж по карте</TextPrimary>
            <SignButton view="on-color" onClick={() => setIsOpenedSchedule(true)}>
              График платежей
            </SignButton>
          </TopContent>
          <TextAdditional>Дата платежа</TextAdditional>
          <BottomContent>
            {dateInfo.icon && (
              <DateInfoIcon bgColor={dateInfo.iconBgColor}>{dateInfo.icon}</DateInfoIcon>
            )}
            <TextSecondary>{dateInfo.text}</TextSecondary>
          </BottomContent>
          <DialogPaymentSchedule
            title={'График платежей'}
            contractNumber={product.contractNumber}
            isOpen={isOpenedSchedule}
            onClose={() => setIsOpenedSchedule(false)}
          />
        </TabListWrapper>
      )}
    </>
  )
}
