import { HttpClientModel } from '@/core'
import { ResolveWithSystemErrorResponse } from '@/models/ResolveWithSystemErrorResponse'
import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { CurrencyType } from '@/models/CurrencyType'
import { PaymentSystemType } from '@/models/PaymentSystemType'

export namespace CardsMcpModel {
  export type Func = (options?: Partial<{ signal: AbortSignal }>) => Promise<Response>
  export type Response = HttpClientModel.Response<ResponseData>
  export type ErrorResponse = HttpClientModel.ErrorResponse<ErrorResponseData, any>
  export interface ResolveWithDataResponse {
    type: ResponseType.ResolveWithData
    products: Product[]
  }

  export type Product = CardsMcp

  export enum ProductType {
    DebitCard = 'DebitCard',
  }

  export enum CategoryType {
    Card = 'Card',
  }

  export enum CardRole {
    Primary = 'PRIMARY',
    Additional = 'ADDITIONAL',
    Virtual = 'VIRTUAL',
  }

  export enum CardStatusDisplayed {
    Unknown = 'Unknown',
    Active = 'Active',
    New = 'New',
    NotActive = 'NotActive',
    Blocked = 'Blocked',
    BlockedByBank = 'BlockedByBank',
    Closed = 'Closed',
    Delivery = 'Delivery',
  }

  export enum MarkType {
    Kids = 'Kids',
    Polza = 'Polza',
    Mir = 'Mir',
    MirPolzaZP = 'MirPolzaZP',
    PolzaGold = 'PolzaGold',
    KeyNew = 'KeyNew',
    UsdEur = 'UsdEur',
    ForTwo = 'ForTwo',
    GreenPolza = 'GreenPolza',
    Ingame = 'Ingame',
    Travel = 'Travel',
    Cosmos = 'Cosmos',
    VisaGold = 'VisaGold',
    Undefined = 'Undefined',
  }

  export type Currency = 'RUR' | 'USD' | 'EUR'

  export interface CardsMcp {
    isExpiredData: string
    contractNumber: string
    productName: string
    contractStatus: string
    availableBalance: number
    cardStatus: CardsMcpModel.CardStatusDisplayed
    maskCardNumber: string
    contractBillingDay: string
    cardRole: CardsMcpModel.CardRole
    isDigital: boolean
    isFamily: boolean
    currency: Currency
    productType: CardsMcpModel.ProductType.DebitCard
    accountNumber: string
    cardMbr: number
    displayOrder: number
    isPlasticActivationAvailable: boolean
    expiration: string
    isSalaryCard: boolean
    isArrestedAccount: boolean
    isRestrictedBalance: boolean
    isResident: boolean
    productId: number
    enableApplePay: boolean
    enableGooglePay: boolean
    enableSamsungPay: boolean
    availablePlasticCardIssuance: boolean
    availableCardInfo: boolean
    isPinGenerated: boolean
    salaryProject: boolean
    isChildCard: boolean
    childFirstName?: string
    childLastName?: string
    isPlasticInDelivery: boolean
    isFreeLimitsAvailable: boolean
    existingCardInfo: string
    productIdent: string
    cardNumberInDelivery: string
    isPrepaid: boolean
    contractActivateDate: string
    reissueUponExpirationStatus: number
    isPolza: boolean

    // Custom props
    lastFourCardDigits: string
    paymentSystem: PaymentSystemType
    description: string
    markType: MarkType
    bgColor: string
    bgColorTheme: 'light' | 'dark'
    id: string
    categoryType: CategoryType.Card
    currencyType: CurrencyType
    settingWidgetId: string
    image1x: string
    image2x: string
  }

  export type ResponseData = ResolveWithDataResponse | ResponseDataResolveWithSystemErrorResponse
  export type ResponseDataResolveWithSystemErrorResponse = ResolveWithSystemErrorResponse
  export type ErrorResponseData = RejectedWithSystemErrorResponse
  export type RequestHeaders = null
}
