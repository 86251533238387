import { useQuery, UseQueryOptions } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { stringTemplate } from '@/utils/string-template'
import { adapter } from './adapters/adapter'
import { PaymentsDetalizationModel } from './PaymentsDetalizationModel'

export const paymentsDetalization: PaymentsDetalizationModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.products.paymentsDetalization,
    {} as ApiManagerModel.ApiOption
  )
  const response = await HttpClient.get<
    PaymentsDetalizationModel.ResponseData,
    void,
    PaymentsDetalizationModel.RequestHeaders,
    PaymentsDetalizationModel.ErrorResponse
  >({
    url: stringTemplate<{ contractNumber: string; forceUpdate: boolean }>(path, {
      contractNumber: props.contractNumber,
      forceUpdate: false,
    }),
    headers: {
      _ver_: props.apiVersion,
      _os_: '4',
    },
    responseAdapter: adapter,
    control,
  })

  return response
}

function paymentsDetalizationKey(props: { contractNumber: string }) {
  return ['v1/api/Cards/PaymentsDetalization', props.contractNumber]
}

export function usePaymentsDetalizationQuery<T = PaymentsDetalizationModel.ResponseData>(
  props: PaymentsDetalizationModel.Props,
  options?: Omit<
    UseQueryOptions<
      PaymentsDetalizationModel.ResponseData,
      PaymentsDetalizationModel.ErrorResponse,
      T
    >,
    'queryKey' | 'queryFn'
  >
) {
  const key = paymentsDetalizationKey(props)
  const result = useQuery(
    key,
    async () => paymentsDetalization(props).then((res) => res.data),
    options
  )

  return result
}
