import { HttpClientModel } from '@/core'
import { ResolveWithSystemErrorResponse } from '@/models/ResolveWithSystemErrorResponse'
import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'

/**
 * DebitCards
 * @see https://confluence.homecredit.ru/confluence/pages/viewpage.action?pageId=161654649
 */
export namespace CreditCardsModel {
  export type Func = (props: Props) => Promise<Response>
  export type Response = HttpClientModel.Response<ResponseData>
  export type ErrorResponse = HttpClientModel.ErrorResponse<ErrorResponseData, any>
  export interface ResolveWithDataResponse {
    type: ResponseType.ResolveWithData
    product: Product
  }

  export type Props = {
    maskCardNumber: string
    cardMbr: number
  }

  export type Product = CreditCard

  export enum ProductType {
    DebitCard = 'DebitCard',
  }
  export enum CategoryType {
    Card = 'Card',
  }

  export enum CardRole {
    Primary = 'PRIMARY',
    Additional = 'ADDITIONAL',
    Virtual = 'VIRTUAL',
  }
  export interface CreditCard {
    isExpiredData: string
    maskCardNumber: string
    availableBalance: number
    contractNumber: string
    productName: string
    contractStatus: string
    cardStatus: string
    cardRole: string
    isDigital: boolean
    currency: string
    accountNumber: string
    cardMbr: number
    isSalaryCard: boolean
    isArrestedAccount: boolean
    isRestrictedBalance: boolean
    isResident: boolean
    isPinGenerated: boolean
    isSalaryProject: boolean
    isEnableApplePay: boolean
    isEnableGooglePay: boolean
    isEnableSamsungPay: boolean
    isAvailablePlasticCardIssuance: boolean
    isAvailableCardInfo: boolean
    isChildCard: boolean
    isPlasticInDelivery: boolean
    freeLimits: boolean
    isFamilyCard: boolean
    productType: string
    currencyCode: number
    balance: number
    isPolza: boolean
    cardType: string
    isSalaryCardApplicationAvailable: boolean
    lastFourCardDigits: string
  }
  export type ResponseData = ResolveWithDataResponse | ResponseDataResolveWithSystemErrorResponse
  export type ResponseDataResolveWithSystemErrorResponse = ResolveWithSystemErrorResponse
  export type ErrorResponseData = RejectedWithSystemErrorResponse
  export type RequestHeaders = null
}
