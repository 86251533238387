import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { HttpClient } from '@/core'
import { AdapterModel } from './AdapterModel'
import { formatDate } from '@/utils/formatDate'

const toCurrentFormatDate = (date: string) => {
  if (!date) {
    return date
  }

  const parse = new Date(Date.parse(date))
  return formatDate(parse, 'DD.MM.YYYY')
}

export const adapter: AdapterModel.Func = (data) => {
  const errorData = data as AdapterModel.ResponseDataError

  if (!errorData || errorData?.status >= 400) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [
        {
          message: 'Произошла ошибка на стороне сервера',
          type: 'INTERNAL_SERVER_ERROR',
        },
      ],
    }
    throw HttpClient.createError(errorData.status, exception)
  }

  try {
    const successData = data as AdapterModel.ResponseDataSuccess

    return {
      type: ResponseType.ResolveWithData,
      contractNumber: successData.contractNumber,
      nextPaymentDate: toCurrentFormatDate(successData.nextPaymentDate),
      contractBillingDate: successData.contractBillingDate,
      debtAmount: successData.debtAmount,
      debtDays: successData.debtDays,
      visualStyle: successData.visualStyle,
      daysForPaymentLeft: successData.daysForPaymentLeft,
      debtDaysWordAgreement: successData.debtDaysWordAgreement,
      daysLeftWordAgreement: successData.daysLeftWordAgreement,
      paymentsDetails: successData.paymentsDetails,
      isExpiredData: successData.isExpiredData,
    }
  } catch (error) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [{ message: 'Ошибка при обработке полученных данных', type: 'ADAPTER_ERROR' }],
    }

    throw HttpClient.createError(500, exception)
  }
}
