import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { MutateOptions, useMutation } from 'react-query'
import { adapter } from './adapters/adapter'
import { GetCardPropertyModel } from './GetCardPropertyModel'
import { stringTemplate } from '@/utils/string-template'

export const getCardProperty: GetCardPropertyModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.digitalCard.getCardProperty,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.post<
    GetCardPropertyModel.ResponseData,
    void,
    GetCardPropertyModel.RequestData,
    GetCardPropertyModel.RequestHeaders,
    GetCardPropertyModel.ErrorResponse
  >({
    url: stringTemplate<{ contractNumber: string }>(path, {
      contractNumber: props.contractNumber,
    }),
    data: {
      maskCard: props.maskCardNumber,
      mbr: props.cardMBR,
    },
    headers: null,
    responseAdapter: adapter,
    options: {
      auth: true,
    },
    control,
  })
  return response
}

export function useGetCardPropertyMutation(
  options?: MutateOptions<
    GetCardPropertyModel.Response,
    GetCardPropertyModel.ErrorResponse,
    GetCardPropertyModel.Props
  >
) {
  return useMutation(getCardProperty, options)
}
