import React from 'react'
import { DialogMore } from '../../components/DialogMore'
import { DialogPinCode } from '../DialogPinCode'
import { DialogFreezeCard } from '@/features/dialog-freeze-card'
import { DialogBankDetails } from '@/features/dialog-bank-details'
import { DialogCardDetails } from '@/features/dialog-card-details'
import { useGetWidgetInfoSelector } from '../Widget/useGetWidgetInfoSelector'
import { ProductsModel } from '@/api/products/products'
import { CurrencyType } from '@/models/CurrencyType'
import { BaseModal } from '@platform-ui/components'

export enum TabListId {
  Deposit = 'deposit',
  Pay = 'pay',
  More = 'more',
}

export enum TabListMoreId {
  BankDetails = 'bank details',
  CardDetails = 'card details',
  PinCodeChange = 'pin-code change',
  CardBlocking = 'card blocking',
  CardUnlock = 'card unlock',
}

const filteredTabListMore = (
  hidePinCodeChangeTab: boolean,
  cardStatusDisplayed: ProductsModel.CardStatusDisplayed,
  tabListMore: {
    id: string
    title: string
    description: string
    icon: React.ReactNode
    parentTabId: string
  }[],
  cardRole: ProductsModel.CardRole
) =>
  tabListMore
    .filter(({ id }) => {
      /**
       * Скрыть реквизиты для доп. дебетовой карты
       */
      if (id === TabListMoreId.CardDetails || id === TabListMoreId.BankDetails)
        return cardRole === ProductsModel.CardRole.Primary

      /**
       * Скрыть табы блокировка и разблокировки карты, если в cardStatusDisplayed "NEW" или "CLOSED" или "NOT_ACTIVE" или "BLOCKED_BY_BANK"
       */
      if (
        cardStatusDisplayed === ProductsModel.CardStatusDisplayed.New ||
        cardStatusDisplayed === ProductsModel.CardStatusDisplayed.Closed ||
        cardStatusDisplayed === ProductsModel.CardStatusDisplayed.NotActive ||
        cardStatusDisplayed === ProductsModel.CardStatusDisplayed.BlockedByBank
      )
        return id !== TabListMoreId.CardUnlock && id !== TabListMoreId.CardBlocking
      /**
       * Скрыть таб блокировка карты, если в cardStatusDisplayed не "BLOCKED"
       * Скрыть таб разблокировки карты, если в cardStatusDisplayed не "ACTIVE"
       */
      if (
        cardStatusDisplayed === ProductsModel.CardStatusDisplayed.Active ||
        cardStatusDisplayed === ProductsModel.CardStatusDisplayed.Blocked
      )
        return cardStatusDisplayed === ProductsModel.CardStatusDisplayed.Active
          ? id !== TabListMoreId.CardUnlock
          : id !== TabListMoreId.CardBlocking

      return true
    })
    .filter(({ id }) => {
      /**
       * Скрыть таб смены пин-кода если показан виджет
       */
      if (hidePinCodeChangeTab) return id !== TabListMoreId.PinCodeChange

      return true
    })

export type TabBarContentProps = {
  title: string
  activeTabId: string
  setActiveTabId: (id: string) => void
  onClose: () => void
  goBack: () => void
  cardStatusDisplayed: ProductsModel.CardStatusDisplayed
  isPlasticActivationAvailable: boolean
  maskCardNumber: string
  contractNumber: string
  cardMBR: number
  currencyType: CurrencyType
  accountNumber: string
  tabListMore: {
    id: string
    title: string
    description: string
    icon: React.ReactNode
    parentTabId: string
  }[]
  cardRole: ProductsModel.CardRole
  isOpen: boolean
}

export const DebitDialogContent = ({
  title,
  activeTabId,
  setActiveTabId,
  onClose,
  goBack,
  cardStatusDisplayed,
  isPlasticActivationAvailable,
  maskCardNumber,
  contractNumber,
  cardMBR,
  currencyType,
  accountNumber,
  tabListMore,
  cardRole,
  isOpen,
}: TabBarContentProps) => {
  const { hidePinCodeChangeTab, sessionUID, mobilePhone } = useGetWidgetInfoSelector(
    cardStatusDisplayed,
    isPlasticActivationAvailable,
    maskCardNumber
  )

  return (
    <>
      <BaseModal
        title={title}
        onClose={onClose}
        isOpen={isOpen}
        isBackBtn={activeTabId !== TabListId.More}
        backBtnClick={goBack}
      >
        {activeTabId === TabListId.More && (
          <DialogMore
            data={filteredTabListMore(
              hidePinCodeChangeTab,
              cardStatusDisplayed,
              tabListMore,
              cardRole
            )}
            setActiveTabId={setActiveTabId}
          />
        )}
        {activeTabId === TabListMoreId.BankDetails && (
          <DialogBankDetails
            goBack={goBack}
            maskCardNumber={maskCardNumber}
            contractNumber={contractNumber}
            cardMBR={cardMBR}
            currencyType={currencyType}
            accountNumber={accountNumber}
          />
        )}
        {activeTabId === TabListMoreId.CardDetails && (
          <DialogCardDetails
            goBack={goBack}
            maskCardNumber={maskCardNumber}
            contractNumber={contractNumber}
            cardMBR={cardMBR}
          />
        )}
        {activeTabId === TabListMoreId.CardBlocking && (
          <DialogFreezeCard
            cardStatus={cardStatusDisplayed}
            onClose={onClose}
            goBack={goBack}
            cardNumber={maskCardNumber}
            cardMBR={cardMBR}
            cardType={'debitCard'}
          />
        )}
        {activeTabId === TabListMoreId.CardUnlock && (
          <DialogFreezeCard
            cardStatus={cardStatusDisplayed}
            onClose={onClose}
            goBack={goBack}
            cardNumber={maskCardNumber}
            cardMBR={cardMBR}
            cardType={'debitCard'}
          />
        )}
        {activeTabId === TabListMoreId.PinCodeChange && (
          <DialogPinCode
            sessionUID={sessionUID}
            mobilePhone={mobilePhone}
            closeModalHandler={onClose}
          />
        )}
      </BaseModal>
    </>
  )
}
