import { useQuery, UseQueryOptions } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { stringTemplate } from '@/utils/string-template'
import { adapter } from './adapters/adapter'
import { PaymentsScheduleModel } from './PaymentsScheduleModel'

export const paymentsSchedule: PaymentsScheduleModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.products.paymentsSchedule,
    {} as ApiManagerModel.ApiOption
  )
  const response = await HttpClient.get<
    PaymentsScheduleModel.ResponseData,
    void,
    PaymentsScheduleModel.RequestHeaders,
    PaymentsScheduleModel.ErrorResponse
  >({
    url: stringTemplate<{ contractNumber: string; forceUpdate: boolean }>(path, {
      contractNumber: props.contractNumber,
      forceUpdate: false,
    }),
    headers: {
      _ver_: props.apiVersion,
      _os_: '4',
    },
    responseAdapter: adapter,
    control,
  })

  return response
}

function paymentsScheduleKey(props: { contractNumber: string }) {
  return ['v1/api/Cards/PaymentsSchedule', props.contractNumber]
}

export function usePaymentsScheduleQuery<T = PaymentsScheduleModel.ResponseData>(
  props: PaymentsScheduleModel.Props,
  options?: Omit<
    UseQueryOptions<PaymentsScheduleModel.ResponseData, PaymentsScheduleModel.ErrorResponse, T>,
    'queryKey' | 'queryFn'
  >
) {
  const key = paymentsScheduleKey(props)
  const result = useQuery(key, async () => paymentsSchedule(props).then((res) => res.data), options)

  return result
}
