import React from 'react'
import { ApiStatus } from '@/core'
import { Button } from '@platform-ui/components/Button'
import { Headline20, Body16 } from '@platform-ui/typography'
import { WidgetWrapper, WidgetDesc, ErrorWidgetWrapper } from './styled'
import { useGetWidgetInfoSelector } from './useGetWidgetInfoSelector'
import { ProductsModel } from '@/api/products/products'

export type WidgetComponentProps = {
  title: string
  desc: string
  buttonText: string
  buttonClick: () => void
}

export const WidgetComponent = ({ title, desc, buttonText, buttonClick }: WidgetComponentProps) => {
  return (
    <WidgetWrapper>
      <Headline20>{title}</Headline20>
      <WidgetDesc>{desc}</WidgetDesc>
      <Button isFullWidth onClick={buttonClick}>
        {buttonText}
      </Button>
    </WidgetWrapper>
  )
}

export type WidgetProps = {
  maskCardNumber: string
  cardStatusDisplayed: ProductsModel.CardStatusDisplayed
  isPlasticActivationAvailable: boolean
}

export const Widget = ({
  maskCardNumber,
  cardStatusDisplayed,
  isPlasticActivationAvailable,
}: WidgetProps) => {
  const { status, data, retry } = useGetWidgetInfoSelector(
    cardStatusDisplayed,
    isPlasticActivationAvailable,
    maskCardNumber
  )

  if (status === ApiStatus.Rejected) {
    return (
      <ErrorWidgetWrapper>
        <Body16>Не удалось загрузить информацию об активации карты</Body16>
        <Button onClick={retry} sizeSm={'m'} view="ghost">
          Попробовать снова
        </Button>
      </ErrorWidgetWrapper>
    )
  }

  if (!data || status === ApiStatus.Idle) {
    return null
  }

  return (
    <WidgetComponent
      title={data.title}
      desc={data.desc}
      buttonText={data.buttonText}
      buttonClick={data.buttonClick}
    />
  )
}
