import React, { useState } from 'react'
import { TabList } from '@/components/TabList'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { observer } from 'mobx-react-lite'
import { ClientProductsModel } from '@/api/products/clientProducts'
import { ProductsModel } from '@/api/products/products'
import { DepositSecondaryIcon, MenuOffersTransactionsIcon, ServicesIcon } from '@platform-ui/icons'
import { ProductInDevelopmentDialog } from '@/features/product-lists/components/ProductInDevelopmentDialog'
import { MoreIcon } from '@platform-ui/icons/MoreIcon'
import { CreditDialogContent } from '@/features/credit-card/containers/TabBar/CreditDialogContent'
import { FreezeIcon } from '@platform-ui/icons/FreezeIcon'
// import {TRANSACTION_TYPE} from "@/features/transfers-list/constants";

export enum TabListId {
  Deposit = 'deposit',
  Pay = 'pay',
  CardDetails = 'details',
  More = 'more',
}

export enum TabListMoreId {
  CardDetails = 'card details',
  CardBlocking = 'card blocking',
  CardUnlock = 'card unlock',
}

export type TabListItem = {
  id: string
  title: string
  titleModal: string
  icon: React.ReactNode
  isDisabled?: boolean
  parentTabId: string
}

export type TabListMoreItem = {
  id: string
  title: string
  titleModal: string
  description: string
  icon: React.ReactNode
  parentTabId: string
}

export const getTabListMore = () => {
  const tabListMore: TabListMoreItem[] = [
    {
      id: TabListMoreId.CardDetails,
      title: 'Реквизиты карты',
      titleModal: 'Реквизиты карты',
      description: 'Номер карты, срок действия и CVV2',
      icon: <MenuOffersTransactionsIcon color="icons/primary" />,
      parentTabId: TabListId.More,
    },
    {
      id: TabListMoreId.CardBlocking,
      title: 'Заблокировать карту',
      titleModal: 'Заблокировать карту',
      description: 'Запретить все операции по карте',
      icon: <FreezeIcon color="icons/primary" />,
      parentTabId: TabListId.More,
    },
    {
      id: TabListMoreId.CardUnlock,
      title: 'Разблокировать карту',
      titleModal: 'Разблокировать карту',
      description: 'Разрешить все операции по карте',
      icon: <FreezeIcon color="icons/primary" />,
      parentTabId: TabListId.More,
    },
  ]
  return { tabListMore }
}

export const getTabList = (isMobile: boolean) => {
  const tabList: TabListItem[] = [
    {
      id: TabListId.Deposit,
      title: 'Пополнить',
      titleModal: 'Пополнить',
      icon: <DepositSecondaryIcon color="icons/primary" size={isMobile ? 's12' : 's16'} />,
      parentTabId: '',
    },
    {
      id: TabListId.Pay,
      title: 'Оплатить',
      titleModal: 'Оплатить',
      icon: <ServicesIcon color="icons/primary" size={isMobile ? 's12' : 's16'} />,
      parentTabId: '',
    },
    {
      id: TabListId.More,
      title: 'Ещё',
      titleModal: 'Ещё',
      icon: <MoreIcon color="icons/primary" size={isMobile ? 's12' : 's16'} />,
      parentTabId: '',
    },
  ]

  return {
    tabList,
  }
}

export interface TabBarProps {
  accountNumber: string
  productById?: Pick<
    ClientProductsModel.CreditLoan,
    | 'contractNumber'
    | 'productName'
    | 'productTypeNumber'
    | 'creditAmount'
    | 'accountNumber'
    | 'dateSign'
    | 'smsPackService'
    | 'accountBalance'
  >
  productId: string
  maskCardNumber: string
  contractNumber: string
  cardMBR: number
  cardType: ClientProductsModel.CardType
  cardStatus: ProductsModel.CardStatusDisplayed
  contractStatus: ProductsModel.GuiContractStatus
}

const getActiveTab = (
  activeTabId: string,
  tabList: TabListItem[],
  tabListMore: TabListMoreItem[]
) => [...tabList, ...tabListMore].find(({ id }) => id === activeTabId)

export const TabBar = observer(
  ({
    productId,
    contractNumber,
    cardMBR,
    maskCardNumber,
    cardStatus,
    cardType,
    contractStatus,
  }: TabBarProps) => {
    const { config, eventBus } = useAppContext<AppContext>()
    const isMobile = config.device.isMobile

    const [isOpenModal, setIsOpenModal] = useState(false)
    const [activeTabId, setActiveTabId] = useState('')

    const { tabList } = getTabList(isMobile)
    const { tabListMore } = getTabListMore()

    const activeTab = getActiveTab(activeTabId, tabList, tabListMore)
    const title = activeTab ? activeTab.titleModal : ''

    const [isOpenInDevelopmentModal, setIsOpenInDevelopmentModal] = useState(false)

    const onOpen = (id: string) => () => {
      if (id === TabListId.Deposit) {
        setIsOpenInDevelopmentModal(true)
        // eventBus.transfersModal.open.emit({ productId, type: TRANSACTION_TYPE.betweenAccounts, isHaveInitTo: true })
        return
      }

      if (id === TabListId.Pay) {
        eventBus.payListModal.open.emit({ id: productId })
        return
      }

      setActiveTabId(id)
      setIsOpenModal(true)
    }

    const onClose = (): void => {
      setActiveTabId('')
      setIsOpenModal(false)
    }

    const goBack = () => {
      if (activeTab.parentTabId) {
        setActiveTabId(activeTab.parentTabId)
      }
    }

    return (
      <>
        <TabList data={tabList} onOpen={onOpen} />
        <CreditDialogContent
          title={title}
          onClose={onClose}
          isOpen={isOpenModal}
          activeTabId={activeTabId}
          goBack={goBack}
          tabListMore={tabListMore}
          setActiveTabId={setActiveTabId}
          contractNumber={contractNumber}
          cardMBR={cardMBR}
          maskCardNumber={maskCardNumber}
          cardStatus={cardStatus}
          cardType={cardType}
          contractStatus={contractStatus}
        />
        <ProductInDevelopmentDialog
          isOpen={isOpenInDevelopmentModal}
          onClose={() => setIsOpenInDevelopmentModal(false)}
        />
      </>
    )
  }
)
