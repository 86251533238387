import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { SplitedCodeInputs } from '@platform-ui/components/SplitedCodeInputs'
import { Container } from '@platform-ui/components/Container'
import { Row } from '@platform-ui/components/Row'
import { Col } from '@platform-ui/components/Col'
import { useSmsCodeState } from './useSmsCodeState'
import { MismatchText, SmsCodeDesc } from './styled'
import { Button } from '@platform-ui/components'
import { useStore } from '@/features/pin-code-modal/store'
import { pinCodeWidgetSteps } from '@/features/pin-code-modal/PinCodeContent'
import styled from '@emotion/styled'

export const ButtonWrapper = styled.div(() => ({
  position: 'fixed',
  padding: '32px 16px',
  width: '100%',
  left: 0,
  bottom: 0,
}))

export const SmsCodeStep = observer(() => {
  const widgetStore = useStore()
  const {
    openFormSms,
    codeValue,
    splitedCodeStatus,
    handleChangeSplitedCode,
    handleFulfilledSplitedCode,
    resendSmsAvailable,
    statusPinCheckSms,
    statusSendSms,
    statusResendSms,
    mismatchResultText,
    handleClick,
    timerStore,
  } = useSmsCodeState(widgetStore.sessionUID, widgetStore.code)

  useEffect(() => {
    if (statusSendSms === 'error' || statusPinCheckSms === 'error' || statusResendSms === 'error') {
      widgetStore.step = pinCodeWidgetSteps.SMS_CODE_ERROR
    }
  }, [statusSendSms, statusPinCheckSms, statusResendSms])

  useEffect(() => {
    timerStore.init({
      attempt: 3,
      timeWaitSec: 120,
    })

    return () => {
      timerStore.destroy()
    }
  }, [])

  useEffect(() => {
    openFormSms()
  }, [])

  return (
    <Container flexSm>
      <Row flexSm>
        <Col
          displaySm="flex"
          flexDirectionSm="column"
          alignItemsSm="center"
          justifyContentSm="center"
        >
          <SplitedCodeInputs
            length={4}
            value={codeValue}
            onChange={handleChangeSplitedCode}
            onFullfilled={handleFulfilledSplitedCode}
            isAutoFocus
            status={splitedCodeStatus}
          />

          {mismatchResultText && <MismatchText>{mismatchResultText}</MismatchText>}

          <SmsCodeDesc>
            Мы выслали код подтверждения на указанный вами номер
            <br />
            {widgetStore.mobilePhone}
          </SmsCodeDesc>
        </Col>
      </Row>
      {resendSmsAvailable && (
        <ButtonWrapper>
          <Button isFullWidth isDisabled={!timerStore.isOverTimerWait} onClick={handleClick}>
            {timerStore.isOverTimerWait
              ? 'Запросить код повторно'
              : `Повторно запросить код через ${timerStore.timeWaitString}`}
          </Button>
        </ButtonWrapper>
      )}
    </Container>
  )
})
