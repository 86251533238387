import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { MutateOptions, useMutation } from 'react-query'
import { adapter } from './adapters/adapter'
import { CheckSmsModel } from './CheckSmsModel'
import { stringTemplate } from '@/utils/string-template'

export const checkSms: CheckSmsModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.digitalCard.checkSms,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.post<
    CheckSmsModel.ResponseData,
    void,
    CheckSmsModel.RequestData,
    CheckSmsModel.RequestHeaders,
    CheckSmsModel.ErrorResponse
  >({
    url: stringTemplate<{ requestId: string }>(path, {
      requestId: props.requestId,
    }),
    headers: {
      _ver_: props.apiVersion,
      _os_: '4',
    },
    data: {
      otp: props.otp,
    },
    responseAdapter: adapter,
    control,
  })
  return response
}

export function useCheckSmsMutation(
  options?: MutateOptions<CheckSmsModel.Response, CheckSmsModel.ErrorResponse, CheckSmsModel.Props>
) {
  return useMutation(checkSms, options)
}
