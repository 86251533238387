import React from 'react'
import styled from '@emotion/styled'
import { Body16 } from '@platform-ui/typography'
import { Shimmer } from '@platform-ui/components/Shimmer'

export const WidgetWrapper = styled.div(({ theme }) => {
  return {
    background: theme.color['background/secondary'],
    borderRadius: 20,
    marginTop: 24,
    padding: '28px 32px',

    [`${theme.media.md}`]: {
      marginTop: 40,
    },
  }
})

export const WidgetDesc = styled(Body16)(({ theme }) => {
  return {
    color: theme.color['text/secondary'],
    margin: '8px 0 24px',

    [`${theme.media.md}`]: {
      margin: '8px 0 32px',
    },
  }
})

export const WidgetShimmer = styled(Shimmer)(() => {
  return {
    borderRadius: 20,
    marginTop: 40,
    padding: '28px 32px',
  }
})

export const ErrorWidgetWrapper = styled.div(({ theme }) => {
  return {
    background: theme.color['background/secondary'],
    borderRadius: 12,
    textAlign: 'center',
    marginTop: 24,
    padding: 16,

    [`${theme.media.md}`]: {
      marginTop: 40,
    },
  }
})
