import { useQuery, UseQueryOptions } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { GetCardStatusModel } from './GetCardStatusModel'
import { adapter } from './adapters/adapter'

export const getCardStatus: GetCardStatusModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.cardActivation.getCardStatus,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.post<
    GetCardStatusModel.ResponseData,
    void,
    GetCardStatusModel.RequestData,
    GetCardStatusModel.RequestHeaders,
    GetCardStatusModel.ErrorResponse
  >({
    url: path,
    data: {
      maskCard: props.maskCardNumber,
    },
    headers: null,
    responseAdapter: adapter,
    control,
  })

  return response
}

export function getCardStatusQueryKey(props: { maskCardNumber: string }) {
  return ['/api/Card/CardActivation/getCardStatus', props.maskCardNumber]
}

export function useGetCardStatusQuery<T = GetCardStatusModel.ResponseData>(
  props: GetCardStatusModel.Props,
  options?: Omit<
    UseQueryOptions<GetCardStatusModel.ResponseData, GetCardStatusModel.ErrorResponse, T>,
    'queryKey' | 'queryFn'
  >
) {
  const key = getCardStatusQueryKey(props)
  return useQuery(key, async () => getCardStatus(props).then((res) => res.data), options)
}
