import { MutateOptions, useMutation } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { ResendSmsModel } from './ResendSmsModel'
import { adapter } from './adapters/adapter'
import { stringTemplate } from '@/utils/string-template'

export const resendSms: ResendSmsModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.cardActivation.resendSms,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.post<
    ResendSmsModel.ResponseData,
    void,
    ResendSmsModel.RequestHeaders,
    ResendSmsModel.ErrorResponse
  >({
    url: stringTemplate<{ sessionUID: string }>(path, {
      sessionUID: props.sessionUID,
    }),
    data: null,
    headers: null,
    responseAdapter: adapter,
    control,
  })

  return response
}

export function useResendSmsMutation(
  options?: MutateOptions<
    ResendSmsModel.Response,
    ResendSmsModel.ErrorResponse,
    ResendSmsModel.Props
  >
) {
  return useMutation(resendSms, options)
}
