import styled from '@emotion/styled'
import { Shimmer } from '@platform-ui/components/Shimmer'

export const ShimmerWrapper = styled.div(() => {
  return {
    padding: '16px 0',
    margin: '16px 0 32px 0',
  }
})

export const ShimmerTitle = styled(Shimmer)(() => {
  return {
    height: 12,
    borderRadius: 12,
    marginBottom: 12,
  }
})

export const ShimmerDesc = styled(Shimmer)(() => {
  return {
    height: 12,
    width: 85,
    borderRadius: 12,
  }
})

export const PaymentScheduleWrapper = styled.div(() => {
  return {
    marginBottom: 40,
  }
})
