import React, { useEffect } from 'react'
import { TabBar } from './containers/TabBar'
import { Widget } from './containers/Widget'
import { History } from '@/features/history'
import { useDebitCardsSelector } from './selectors/useDebitCardsSelector'
import { CardsMcpModel } from '@/api/products/cardsMcp'
import { ApiStatus } from '@/core'
import { TabBarShimmer } from './components/TabBarShimmer'
import { CashbackWidget } from './styled'
import { useSettingsQuery } from '@/api/settings/settings'
import { ResponseType } from '@/models/ResponseType'
import { CardLockTile } from '@/features/card-lock-tile'
import { useAppContext } from '../../core'
import { AppContext } from '../../models/AppContext'
import { observer } from 'mobx-react-lite'

export type DebitCardDetailsProps = {
  productId: string
}

export const DebitCardDetails = observer(({ productId }: DebitCardDetailsProps) => {
  const { products, status } = useDebitCardsSelector()
  const productById = products[productId] as CardsMcpModel.Product
  const { data: settingsData } = useSettingsQuery()

  const { popups, eventBus } = useAppContext<AppContext>()

  useEffect(() => {
    if (popups.screens?.debitCard.length > 0) {
      eventBus.popupModal.open.emit({
        popup: popups.screens.debitCard[0],
      })
    }
  }, [popups])

  if (status === ApiStatus.Pending) {
    return <TabBarShimmer />
  }

  if (productById) {
    const {
      maskCardNumber,
      contractNumber,
      cardMbr,
      cardStatus,
      cardRole,
      isPlasticActivationAvailable,
      accountNumber,
      lastFourCardDigits,
      productName,
      image1x,
      bgColor,
      currencyType,
      contractBillingDay,
    } = productById

    const isCashbackEnabled =
      settingsData?.type === ResponseType.ResolveWithData &&
      settingsData.result.enablePolzaMainWidget &&
      productById.currencyType === 'RUB'

    return (
      <>
        {cardStatus === CardsMcpModel.CardStatusDisplayed.Blocked && (
          <CardLockTile
            cardStatus={cardStatus}
            cardMBR={cardMbr}
            cardNumber={maskCardNumber}
            cardType={'debitCard'}
          />
        )}
        <TabBar
          maskCardNumber={maskCardNumber}
          contractNumber={contractNumber}
          cardMBR={cardMbr}
          cardStatusDisplayed={cardStatus}
          isPlasticActivationAvailable={isPlasticActivationAvailable}
          productId={productId}
          currencyType={currencyType}
          accountNumber={accountNumber}
          cardRole={cardRole}
        />
        <Widget
          maskCardNumber={maskCardNumber}
          cardStatusDisplayed={cardStatus}
          isPlasticActivationAvailable={isPlasticActivationAvailable}
        />
        {isCashbackEnabled && <CashbackWidget maskCardNumber={maskCardNumber} />}
        <History
          productType="debitCard"
          maskCardNumber={maskCardNumber}
          accountNumber={accountNumber}
          contractNumber={contractNumber}
          lastFourCardDigits={lastFourCardDigits}
          productName={productName}
          image1x={image1x}
          bgColor={bgColor}
          currencyType={currencyType}
          startContractDate={contractBillingDay}
        />
      </>
    )
  }

  return null
})
