import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { HttpClient } from '@/core'
import { AdapterModel } from './AdapterModel'

export const adapter: AdapterModel.Func = (data) => {
  const errorData = data as AdapterModel.ResponseDataError

  if (!errorData || errorData?.status >= 400) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [
        {
          message: 'Произошла ошибка на стороне сервера',
          type: 'INTERNAL_SERVER_ERROR',
        },
      ],
    }
    throw HttpClient.createError(errorData.status, exception)
  }

  try {
    const successData = data as AdapterModel.ResponseDataSuccess

    return {
      type: ResponseType.ResolveWithData,
      contractNumber: successData.contractNumber,
      covenantId: successData.covenantId,
      title: successData.title,
      description: successData.description,
      periodDescription: successData.periodDescription,
      conditionsTitle: successData.conditionsTitle,
      status: successData.status,
      progress: successData.progress,
      conditions: successData.conditions.map((item) => ({
        conditionId: item.conditionId,
        title: item.title,
        description: item.description,
        remainValue: item.remainValue,
        remainTitle: item.remainTitle,
        currentValue: item.currentValue,
        currentTitle: item.currentTitle,
        status: item.status,
        progress: item.progress,
      })),
    }
  } catch (error) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [{ message: 'Ошибка при обработке полученных данных', type: 'ADAPTER_ERROR' }],
    }

    throw HttpClient.createError(500, exception)
  }
}
