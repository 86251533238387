import { AdapterModel } from './AdapterModel'
import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { HttpClient } from '@/core'
import { PinCheckSmsModel } from '../PinCheckSmsModel'

const createResult = (successData: AdapterModel.ResponseDataSuccess): PinCheckSmsModel.Result => {
  if (!successData.resultCode) {
    const result: PinCheckSmsModel.ResultMatch = {
      resultCode: PinCheckSmsModel.ResultCode.Match,
    }
    return result
  }

  if (successData.resultCode === PinCheckSmsModel.ResultCode.Mismatch) {
    const result: PinCheckSmsModel.ResultMismatch = {
      resultCode: PinCheckSmsModel.ResultCode.Mismatch,
      resendSmsAvailable: !!successData.links.find((item) => item.rel === 'resendsms'),
    }
    return result
  }

  if (successData.resultCode === PinCheckSmsModel.ResultCode.Error) {
    const result: PinCheckSmsModel.ResultError = {
      resultCode: PinCheckSmsModel.ResultCode.Error,
    }
    return result
  }
}

export const adapter: AdapterModel.Func = (data) => {
  const errorData = data as AdapterModel.ResponseDataError
  /**
   * Сервер всегда возвращает status=200 в заголовке
   * Реальный статус приходит в теле ответа
   * 401 или 500+ считаем rejected ответами с наличием критической ошибки
   */
  if (errorData?.statusCode === 401 || errorData?.statusCode >= 500) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: errorData.errorDetails
        ? errorData.errorDetails.map(({ error }) => ({
            type: error,
            message: error,
          }))
        : [],
    }
    throw HttpClient.createError(errorData?.statusCode, exception)
  }

  try {
    const successData = data as AdapterModel.ResponseDataSuccess
    return {
      type: ResponseType.ResolveWithData,
      result: createResult(successData),
    }
  } catch (error) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [{ message: 'Ошибка при обработке полученных данных', type: 'ADAPTER_ERROR' }],
    }

    throw HttpClient.createError(500, exception)
  }
}
