import React from 'react'
import { observer } from 'mobx-react-lite'
import { Row, Col, Button } from '@platform-ui/components'
import { Container } from '@platform-ui/components/Container'
import { SplitedCodeInputs } from '@platform-ui/components/SplitedCodeInputs'
import { pinCodeContent, pinCodeWidgetSteps } from '../../PinCodeContent'
import { useStore } from '../../store'
import styled from '@emotion/styled'

export const ButtonWrapper = styled.div(() => ({
  position: 'fixed',
  padding: '32px 16px',
  width: '100%',
  left: 0,
  bottom: 0,
}))

export const PinCodeStep = observer(() => {
  const widgetStore = useStore()
  const textVariant = pinCodeContent[widgetStore.variant]

  const submit = () => {
    widgetStore.step = pinCodeWidgetSteps.SMS_CODE_FORM
  }

  return (
    <Container flexSm>
      <Row flexSm>
        <Col
          displaySm="flex"
          flexDirectionSm="column"
          alignItemsSm="center"
          justifyContentSm="center"
        >
          <p
            style={{
              textAlign: 'center',
              fontSize: '20px',
              marginBottom: '48px',
              fontWeight: '700',
            }}
          >
            {textVariant.pages.pin.title}
          </p>
          <SplitedCodeInputs
            length={4}
            value={widgetStore.code}
            onChange={widgetStore.setCode}
            onFullfilled={widgetStore.setCode}
            isAutoFocus
          />
          <p
            style={{
              textAlign: 'center',
              width: '368px',
              marginTop: '32px',
              fontWeight: '500',
              lineHeight: '18px',
            }}
          >
            {textVariant.pages.pin.hint}
          </p>
        </Col>
      </Row>

      <ButtonWrapper>
        <Button isFullWidth onClick={submit} isDisabled={widgetStore.code.trim().length < 4}>
          Подтвердить
        </Button>
      </ButtonWrapper>
    </Container>
  )
})
