import React from 'react'
import DefaultPage from '@/layouts/DefaultPage'
import { CardsPage } from '@/containers/CardsPage'
import { AppPage } from '@/models/AppPage'
import { TransfersModal } from '@/features/transfers-modal'
import { PinCodeModal } from '@/features/pin-code-modal'
import { PartnersModal } from '@/features/partners-modal'

const Page: AppPage = () => {
  return (
    <DefaultPage hideMobileHeader>
      <CardsPage />
      <TransfersModal />
      <PinCodeModal />
      <PartnersModal />
    </DefaultPage>
  )
}

export default Page
