import React from 'react'
import { useGetSliderProductsSelector } from './selectors/useGetSliderProductsSelector'
import { SliderChildName, AdditionalInfoWrapper, AdditionalInfoAmount } from './styled'
import { ProductsModel } from '@/api/products/products'
import { PaymentSystemIcon } from '@/components/PaymentSystemIcon'
import { ApiStatus, useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { observer } from 'mobx-react-lite'
import { Slider, SliderShimmer } from '@/components/Slider'
import { Body14 } from '@platform-ui/typography'

export type CardsSliderProps = {
  productId: string
}

/**
 * Вернуть для хоум карты increasedAvailableBalance || accountBalance в зависимости от статуса.
 * Для всех остальных availableBalance
 */
const useAmount = (product: ProductsModel.CreditCard) => {
  if (
    product.consumerCreditInformation &&
    (product.consumerCreditInformation.stage ===
      ProductsModel.ConsumerCreditInformationStage.NotSet ||
      product.consumerCreditInformation.stage ===
        ProductsModel.ConsumerCreditInformationStage.SpendingStage)
  ) {
    return product.aclipInfo.increasedAvailableBalance
  }
  if (
    product.consumerCreditInformation &&
    (product.consumerCreditInformation.stage ===
      ProductsModel.ConsumerCreditInformationStage.PaymentStage ||
      product.consumerCreditInformation.stage ===
        ProductsModel.ConsumerCreditInformationStage.ScheduleFormingStage)
  ) {
    return product.accountBalance
  }
  return product.availableBalance
}

export const CardsSlider = observer(({ productId }: CardsSliderProps) => {
  const { config, productsInContext } = useAppContext<AppContext>()
  const { isMobile } = config.device
  const { status, products } = useGetSliderProductsSelector()

  if (status === ApiStatus.Pending) {
    return <SliderShimmer />
  }

  const product = products[productId] as ProductsModel.DebitCard | ProductsModel.CreditCard

  const productInContext = productsInContext.arr.find((item) => item?.id === productId)

  if (product.productType === ProductsModel.ProductType.CreditCard) {
    const { bgColor, bgColorTheme, currencyType, paymentSystem, cardStatusDisplayed } =
      product as ProductsModel.CreditCard

    const amount = useAmount(product)

    return (
      <Slider
        bgColor={bgColor}
        bgColorTheme={bgColorTheme}
        amount={productInContext?.availableBalance || amount}
        currencyType={currencyType}
        cardStatus={cardStatusDisplayed}
        paymentSystemIcon={
          <PaymentSystemIcon type={paymentSystem} size={isMobile ? '45x18' : '75x30'} />
        }
      >
        {product.isHomeCard && (
          <AdditionalInfoWrapper>
            <Body14>Использовано</Body14>
            <AdditionalInfoAmount amount={product.principalDebtSum} isShowPrecisionWithNullCents />
          </AdditionalInfoWrapper>
        )}
      </Slider>
    )
  }
  if (product.productType === ProductsModel.ProductType.DebitCard) {
    const {
      image1x,
      image2x,
      bgColor,
      bgColorTheme,
      availableBalance,
      currencyType,
      paymentSystem,
      markType,
      isChildCard,
      childFirstName,
      cardStatus,
    } = product as ProductsModel.DebitCard

    return (
      <Slider
        image1x={image1x}
        image2x={image2x}
        bgColor={bgColor}
        bgColorTheme={bgColorTheme}
        backgroundSize={markType === ProductsModel.MarkType.Kids && 'contain'}
        amount={productInContext?.availableBalance || availableBalance}
        currencyType={currencyType}
        cardStatus={cardStatus}
        paymentSystemIcon={
          <PaymentSystemIcon type={paymentSystem} size={isMobile ? '45x18' : '75x30'} />
        }
      >
        {isChildCard && childFirstName && <SliderChildName>{childFirstName}</SliderChildName>}
      </Slider>
    )
  }

  return null
})
