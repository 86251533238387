import React from 'react'
import styled from '@emotion/styled'
import { Shimmer } from '@platform-ui/components/Shimmer'

export const TabBarShimmerWrapper = styled.div(({ theme }) => {
  return {
    borderRadius: 20,
    height: 84,
    backgroundColor: '#F5F5F7',
    padding: 16,
    display: 'flex',
    justifyContent: 'center',
    margin: '24px 0',

    [`${theme.media.md}`]: {
      margin: '40px 0',
      padding: 24,
      borderRadius: 28,
      height: 112,
    },
  }
})

export const TabBarShimmerItem = styled.div(() => {
  return {
    maxWidth: 240,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }
})

export const TabBarShimmerIcon = styled(Shimmer)(({ theme }) => {
  return {
    borderRadius: 20,
    height: 28,
    width: 28,

    [`${theme.media.md}`]: {
      borderRadius: 28,
      height: 32,
      width: 32,
    },
  }
})

export const TabBarShimmerText = styled(Shimmer)(({ theme }) => {
  return {
    borderRadius: 10,
    height: 12,
    width: 56,
    marginTop: 8,

    [`${theme.media.md}`]: {
      borderRadius: 28,
      width: 104,
      marginTop: 16,
    },
  }
})

export const TabBarShimmer = () => {
  return (
    <TabBarShimmerWrapper>
      {Array.from({ length: 3 }).map((_, number) => (
        <TabBarShimmerItem key={number}>
          <TabBarShimmerIcon />
          <TabBarShimmerText />
        </TabBarShimmerItem>
      ))}
    </TabBarShimmerWrapper>
  )
}
