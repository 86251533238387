import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { HttpClient } from '@/core'
import { AdapterModel } from './AdapterModel'
import { GetCardPropertyModel } from '../GetCardPropertyModel'

const createResult = (
  successData: AdapterModel.ResponseDataSuccess
): GetCardPropertyModel.Result => {
  if (successData?.resultCode === GetCardPropertyModel.ResultCode.Blocked) {
    const result: GetCardPropertyModel.ResultBlocked = {
      resultCode: successData?.resultCode,
      resultText: successData?.resultText,
      phone: successData.phone,
    }
    return result
  }

  /**
   * В links приходит ссылка
   * https://balancer-gateway-rp.omni.homecredit.ru:8112/digital-card/v1/cardinfo/7a23982e-f037-4e0c-937e-a0a6b86e2082/check
   *
   * нужно вытащить huid - это наш session (request) id для /check и /resend
   */
  const hrefArr = successData._links.check.href.split('/')
  const requestId = hrefArr[hrefArr.length - 2]

  const result: GetCardPropertyModel.ResultMatchSuccess = {
    phone: successData.phone,
    requestId: requestId,
    resultCode: GetCardPropertyModel.ResultCode.Success,
  }

  return result
}

export const adapter: AdapterModel.Func = (data) => {
  const errorData = data as AdapterModel.ResponseDataError
  /**
   * Сервер всегда возвращает status=200 в заголовке
   * Реальный статус приходит в теле ответа
   * 401 или 500+ считаем rejected ответами с наличием критической ошибки
   */
  if (errorData?.statusCode === 401 || errorData?.statusCode >= 500) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: errorData.errorDetails
        ? errorData.errorDetails.map(({ error }) => ({
            type: error,
            message: error,
          }))
        : [],
    }
    throw HttpClient.createError(errorData?.statusCode, exception)
  }

  try {
    const errorData = data as AdapterModel.ResponseDataError
    /**
     * Есть ошибки от сервера
     */
    if (errorData?.errors && errorData.errors.length && errorData?.statusCode !== 403) {
      return {
        type: ResponseType.ResolveWithSystemError,
        systemErrors: errorData.errorDetails.map(({ error }) => ({
          type: error,
          message: error,
        })),
      }
    }

    const successData = data as AdapterModel.ResponseDataSuccess
    return {
      type: ResponseType.ResolveWithData,
      result: createResult(successData),
    }
  } catch (error) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [{ message: 'Ошибка при обработке полученных данных', type: 'ADAPTER_ERROR' }],
    }

    throw HttpClient.createError(500, exception)
  }
}
