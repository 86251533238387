import React, { useState } from 'react'
import { FreezeIcon } from '@platform-ui/icons/FreezeIcon'
import { ChevronRightIcon } from '@platform-ui/icons/ChevronRightIcon'
import { LockWrapper, MainText, IconStyled, RightContent } from './styled'
import { BaseModal } from '@platform-ui/components'
import { Button } from '@platform-ui/components/Button'
import { DialogFreezeCard } from '@/features/dialog-freeze-card'
import { ClientProductsModel } from '@/api/products/clientProducts'
import { ProductsModel } from '@/api/products/products'
interface CardLockedTileProps {
  cardNumber: string
  cardFreezeStatus?: ProductsModel.CardStatusDisplayed
  cardMBR: number
  cardStatus?: ClientProductsModel.CreditCardStatus
  cardType: ClientProductsModel.CardType
}

export const CardLockTile: React.FC<CardLockedTileProps> = ({
  cardNumber,
  cardMBR,
  cardStatus,
  cardType,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false)

  const handleOpenModal = () => setIsOpenModal(!isOpenModal)

  return (
    <>
      <LockWrapper>
        <RightContent>
          <IconStyled>
            <FreezeIcon color="icons/main" />
          </IconStyled>
          <MainText>Вы заблокировали карту</MainText>
        </RightContent>
        <Button
          view={'on-color'}
          css={'height: 40px'}
          rightIcon={<ChevronRightIcon />}
          onClick={handleOpenModal}
        >
          Разблокировать
        </Button>
      </LockWrapper>
      <BaseModal title={'Разблокировать карту'} onClose={handleOpenModal} isOpen={isOpenModal}>
        <DialogFreezeCard
          cardStatus={cardStatus}
          onClose={handleOpenModal}
          isFromModal={false}
          cardNumber={cardNumber}
          cardMBR={cardMBR}
          cardType={cardType}
        />
      </BaseModal>
    </>
  )
}
