import { TabListId } from '@/features/credit-card/containers/TabBar'
import { BaseModal } from '@platform-ui/components'
import React, { useState } from 'react'
import { TabListMoreItem } from '@/features/credit-card/containers/TabBar/TabBar'
import { DialogMore } from '@/features/credit-card/components/DialogMore'
import { DialogCardDetails } from '@/features/dialog-card-details'
import { DialogFreezeCard } from '@/features/dialog-freeze-card'
import { ClientProductsModel } from '@/api/products/clientProducts'
import { ProductsModel } from '@/api/products/products'

export enum TabListMoreId {
  CardDetails = 'card details',
  CardBlocking = 'card blocking',
  CardUnlock = 'card unlock',
}

type CreditDialogProps = {
  title: string
  onClose: () => void
  isOpen: boolean
  activeTabId: string
  goBack: () => void
  tabListMore: TabListMoreItem[]
  setActiveTabId: (id: string) => void
  maskCardNumber: string
  contractNumber: string
  cardStatus: ProductsModel.CardStatusDisplayed
  cardMBR: number
  cardType: ProductsModel.CardSystem
  contractStatus: ProductsModel.GuiContractStatus
}

const filteredTabListMore = (
  cardStatus: ProductsModel.CardStatusDisplayed,
  contractStatus: ProductsModel.GuiContractStatus,
  cardType: ProductsModel.CardSystem,
  tabListMore: {
    id: string
    title: string
    description: string
    icon: React.ReactNode
    parentTabId: string
  }[]
) =>
  tabListMore.filter(({ id }) => {
    if (cardType !== 'TW') {
      return id !== TabListMoreId.CardUnlock && id !== TabListMoreId.CardBlocking
    }
    if (cardStatus === 'Active') {
      if (contractStatus === 'Cession') {
        return id !== TabListMoreId.CardUnlock && id !== TabListMoreId.CardBlocking
      }
      return id !== TabListMoreId.CardUnlock
    } else {
      return id !== TabListMoreId.CardBlocking
    }
  })

export const CreditDialogContent = ({
  title,
  onClose,
  isOpen,
  activeTabId,
  goBack,
  tabListMore,
  setActiveTabId,
  cardMBR,
  contractNumber,
  maskCardNumber,
  cardStatus,
  cardType,
  contractStatus,
}: CreditDialogProps) => {
  const [isPending] = useState<boolean>(false)
  const isVisibleFreeze =
    activeTabId === TabListMoreId.CardBlocking || activeTabId === TabListMoreId.CardUnlock

  return (
    <BaseModal
      title={title}
      onClose={onClose}
      isOpen={isOpen}
      isBackBtn={activeTabId !== TabListId.More}
      backBtnClick={goBack}
      isPending={isPending}
    >
      {activeTabId === TabListId.More && (
        <DialogMore
          data={filteredTabListMore(cardStatus, contractStatus, cardType, tabListMore)}
          setActiveTabId={setActiveTabId}
        />
      )}
      {activeTabId === TabListMoreId.CardDetails && (
        <DialogCardDetails
          goBack={goBack}
          maskCardNumber={maskCardNumber}
          contractNumber={contractNumber}
          cardMBR={cardMBR}
        />
      )}
      {isVisibleFreeze && (
        <DialogFreezeCard
          cardStatus={cardStatus}
          onClose={onClose}
          goBack={goBack}
          cardNumber={maskCardNumber}
          cardMBR={cardMBR}
          cardType={cardType}
        />
      )}
    </BaseModal>
  )
}
