import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { TabList } from '@/components/TabList'
import { DepositSecondaryIcon } from '@platform-ui/icons/DepositSecondaryIcon'
import { ServicesIcon } from '@platform-ui/icons/ServicesIcon'
import { MoreIcon } from '@platform-ui/icons/MoreIcon'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { ProductInDevelopmentDialog } from '@/features/product-lists/components/ProductInDevelopmentDialog'
import { TRANSACTION_TYPE } from '@/features/transfers-list/constants'

export enum TabListId {
  Deposit = 'deposit',
  Pay = 'pay',
  More = 'more',
}

export const getTabList = (isMobile: boolean) => {
  const tabList: {
    id: string
    title: string
    icon: React.ReactNode
    parentTabId: string
  }[] = [
    {
      id: TabListId.Deposit,
      title: 'Пополнить',
      icon: <DepositSecondaryIcon color="icons/primary" />,
      parentTabId: '',
    },
    {
      id: TabListId.Pay,
      title: 'Оплатить',
      icon: <ServicesIcon color="icons/primary" size={isMobile ? 's12' : 's16'} />,
      parentTabId: '',
    },
    {
      id: TabListId.More,
      title: 'Ещё',
      icon: <MoreIcon color="icons/primary" size={isMobile ? 's12' : 's16'} />,
      parentTabId: '',
    },
  ]

  return {
    tabList,
  }
}

export interface TabBarProps {
  productId: string
  contractNumber: string
}

export const TabBar = observer(({ productId, contractNumber }: TabBarProps) => {
  const { config, eventBus } = useAppContext<AppContext>()
  const isMobile = config.device.isMobile
  const [isOpenInDevelopmentModal, setIsOpenInDevelopmentModal] = useState<boolean>(false)

  const { tabList } = getTabList(isMobile)

  const onOpen = (id: string) => () => {
    if (id === TabListId.Deposit) {
      eventBus.transfersModal.open.emit({
        productId,
        contractNumber,
        type: TRANSACTION_TYPE.betweenAccounts,
        isHaveInitTo: true,
      })
      return
    }
    if (id === TabListId.Pay) {
      eventBus.payListModal.open.emit({ id: productId })
      return
    }
    if (id === TabListId.More) {
      setIsOpenInDevelopmentModal(true)
      return
    }
  }
  return (
    <>
      <ProductInDevelopmentDialog
        isOpen={isOpenInDevelopmentModal}
        onClose={() => setIsOpenInDevelopmentModal(false)}
      />
      <TabList data={tabList} onOpen={onOpen} />
    </>
  )
})
