import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { MutateOptions, useMutation } from 'react-query'
import { adapter } from './adapters/adapter'
import { PinCheckSmsModel } from './PinCheckSmsModel'
import { stringTemplate } from '@/utils/string-template'

export const pinCheckSms: PinCheckSmsModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.cardActivation.pinCheckSms,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.post<
    PinCheckSmsModel.ResponseData,
    void,
    PinCheckSmsModel.RequestData,
    PinCheckSmsModel.RequestHeaders,
    PinCheckSmsModel.ErrorResponse
  >({
    url: stringTemplate<{ sessionUID: string }>(path, {
      sessionUID: props.sessionUID,
    }),
    headers: null,
    data: {
      pin: props.pin,
      smsCode: props.smsCode,
    },
    responseAdapter: adapter,
    control,
  })
  return response
}

export function usePinCheckSmsMutation(
  options?: MutateOptions<
    PinCheckSmsModel.Response,
    PinCheckSmsModel.ErrorResponse,
    PinCheckSmsModel.Props
  >
) {
  return useMutation(pinCheckSms, options)
}
