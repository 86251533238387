import React from 'react'
import { DialogError } from '@/components/DialogError'
import { useDialogCardDetailsState } from './useDialogCardDetailsState'
import { CircularProgress } from '@platform-ui/components/CircularProgress'
import { SmsCode } from '@/features/sms-code'

export type DialogCardDetailsProps = {
  goBack: () => void
  maskCardNumber: string
  contractNumber: string
  cardMBR: number
}

export const DialogCardDetails = ({
  goBack,
  maskCardNumber,
  contractNumber,
  cardMBR,
}: DialogCardDetailsProps) => {
  const { status, data } = useDialogCardDetailsState(maskCardNumber, contractNumber, cardMBR)

  if (status === 'loading') {
    return (
      <CircularProgress
        css={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, margin: 'auto' }}
      />
    )
  }

  if (status === 'error') {
    return <DialogError goBack={goBack} />
  }

  if (status === 'success') {
    return (
      <SmsCode
        phone={data.phone}
        requestId={data.requestId}
        goBack={goBack}
        blockedMessage={data.blockedMessage}
        maskCardNumber={maskCardNumber}
      />
    )
  }

  return null
}
