import { useQuery, UseQueryOptions } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { CardsMcpModel } from './CardsMcpModel'
import { adapter } from './adapters/adapter'

export const cardsMcp: CardsMcpModel.Func = async (options = {}) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.products.cardsMcp,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.get<
    CardsMcpModel.ResponseData,
    any,
    CardsMcpModel.RequestHeaders,
    CardsMcpModel.ErrorResponse
  >({
    url: path,
    headers: null,
    responseAdapter: adapter,
    control,
    signal: options.signal,
  })

  return response
}

function cardsMcpKey() {
  return '/myc-client-products/v1/api/Cards'
}

export function useCardsMcpQuery<T extends CardsMcpModel.ResponseData = CardsMcpModel.ResponseData>(
  options: Omit<
    UseQueryOptions<CardsMcpModel.ResponseData, CardsMcpModel.ErrorResponse, T>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const key = cardsMcpKey()
  const result = useQuery(
    key,
    (state) => cardsMcp({ signal: state.signal }).then((res) => res.response),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      ...options,
    }
  )

  return result
}
