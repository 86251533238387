import React from 'react'
import { Body16 } from '@platform-ui/typography'
import { SplitedCodeInputs } from '@platform-ui/components/SplitedCodeInputs'
import styled from '@emotion/styled'

export const BlockedScreenWrapper = styled.div(() => {
  return {
    maxWidth: 375,
    margin: '-40px auto 0',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  }
})

export const BlockedScreenTitle = styled(Body16)(({ theme }) => {
  return {
    color: theme.color['text/error'],
    marginTop: 32,
    textAlign: 'center',
  }
})

export type DialogCardDetailsBlockedProps = {
  message: string
}

export const DialogCardDetailsBlocked = ({ message }: DialogCardDetailsBlockedProps) => {
  return (
    <BlockedScreenWrapper>
      <SplitedCodeInputs
        length={4}
        value=""
        onChange={() => void 0}
        onFullfilled={() => void 0}
        isReadOnly
      />
      <BlockedScreenTitle>{message}</BlockedScreenTitle>
    </BlockedScreenWrapper>
  )
}
