import styled from '@emotion/styled'
import { Body14 } from '@platform-ui/typography'
import { Currency } from '@/components/Currency'

export const SliderChildName = styled(Body14)(({ theme }) => ({
  background: '#fff',
  color: theme.color['text/main'],
  padding: '2px 8px',
  borderRadius: 20,
  display: 'inline-flex',
  marginRight: 4,
  marginTop: 94,
}))

export const AdditionalInfoWrapper = styled.div(({ theme }) => ({
  marginTop: 70,

  [`${theme.media.md}`]: {
    marginTop: 84,
  },
}))

export const AdditionalInfoAmount = styled(Currency)(({ theme }) => ({
  fontWeight: 600,
  marginTop: 4,
  fontSize: 16,
  display: 'inline-block',

  [`${theme.media.md}`]: {
    fontWeight: 600,
  },
}))
