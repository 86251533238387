import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { HttpClient } from '@/core'
import { AdapterModel } from './AdapterModel'
import { CardsMcpModel } from '../CardsMcpModel'
import { color } from '@platform-ui/core'
import { currencyType } from '@/utils/adapter-helpers/currencyType'
import { createDebitCardId } from '@/utils/adapter-helpers/createDebitCardId'
import { paymentSystem } from '@/utils/adapter-helpers/paymentSystem'
import { createSettingWidgetId } from '@/utils/adapter-helpers/createSettingWidgetId'

const bgImageMapping = (
  markType: CardsMcpModel.MarkType
): {
  image1x: string
  image2x: string
} => {
  switch (markType) {
    default:
      return {
        image1x: '',
        image2x: '',
      }
  }
}

const markTypeMapping = (
  paymentSystem: AdapterModel.PaymentSystem,
  isChildCard: boolean,
  isFamilyCard: boolean
) => {
  const forTwoIds = new Set([true])
  const kidsIds = new Set([true])

  if (forTwoIds.has(isFamilyCard)) return CardsMcpModel.MarkType.ForTwo
  if (kidsIds.has(isChildCard)) return CardsMcpModel.MarkType.Kids

  return CardsMcpModel.MarkType.PolzaGold
}

const bgColorMapping = (
  markType: CardsMcpModel.MarkType
): {
  bgColorTheme: 'light' | 'dark'
  bgColor: string
} => {
  switch (markType) {
    case CardsMcpModel.MarkType.ForTwo:
      return {
        bgColor: color['products/rebranding/forTwo'],
        bgColorTheme: 'dark',
      }
    case CardsMcpModel.MarkType.Kids:
      return {
        bgColor: color['products/kids'],
        bgColorTheme: 'light',
      }
    default:
      return {
        bgColor: color['products/rebranding/Polza'],
        bgColorTheme: 'dark',
      }
  }
}

export const adapter: AdapterModel.Func = (data) => {
  const errorData = data as AdapterModel.ResponseDataError

  if (errorData?.StatusCode === 401 || errorData?.StatusCode >= 500) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: errorData.ErrorDetails
        ? errorData.ErrorDetails.map(({ Error }) => ({
            type: Error,
            message: Error,
          }))
        : [],
    }
    throw HttpClient.createError(errorData?.StatusCode, exception)
  }

  const systemErrorData = data as AdapterModel.ResponseDataSystemError

  if (systemErrorData?.error && systemErrorData?.status) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [
        {
          message: 'Произошла ошибка на стороне сервера',
          type: 'INTERNAL_SERVER_ERROR',
        },
      ],
    }
    throw HttpClient.createError(systemErrorData.status, exception)
  }

  try {
    const successData = data as AdapterModel.ResponseDataSuccess

    return {
      type: ResponseType.ResolveWithData,
      products: successData.map((card) => {
        const markType = markTypeMapping(card.paymentSystem, card.isChildCard, card.isFamilyCard)
        const { bgColor, bgColorTheme } = bgColorMapping(markType)
        const { image1x, image2x } = bgImageMapping(markType)
        const maskCardNumber = card.maskCardNumber.replaceAll(' ', '')

        return {
          ...card,
          id: createDebitCardId(maskCardNumber),
          categoryType: CardsMcpModel.CategoryType.Card,
          productType: CardsMcpModel.ProductType.DebitCard,
          paymentSystem: paymentSystem(card.paymentSystem),
          currencyType: currencyType(card.currency).toClient(),
          lastFourCardDigits: maskCardNumber.slice(-4),
          description: 'Дебетовая',
          markType: markType,
          bgColor,
          bgColorTheme,
          settingWidgetId: createSettingWidgetId(
            card.contractNumber,
            card.maskCardNumber,
            card.cardMbr
          ),
          image1x,
          image2x,
        }
      }),
    }
  } catch (error) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [{ message: 'Ошибка при обработке полученных данных', type: 'ADAPTER_ERROR' }],
    }

    throw HttpClient.createError(500, exception, error)
  }
}
