import { HttpClientModel } from '@/core'
import { ResponseType } from '@/models/ResponseType'
import { ResolveWithSystemErrorResponse } from '@/models/ResolveWithSystemErrorResponse'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'

/**
 * Details
 * @see https://confluence.homecredit.ru/confluence/pages/viewpage.action?pageId=103881850
 */
export namespace GetCardPropertyModel {
  export type Func = (props: Props) => Promise<Response>
  export type Props = {
    contractNumber: string
    maskCardNumber: string
    cardMBR: number
  }
  export type Response = HttpClientModel.Response<ResponseData>
  export type ErrorResponse = HttpClientModel.ErrorResponse<ErrorResponseData>
  export type ResolveWithDataResponse = {
    type: ResponseType.ResolveWithData
    result: Result
  }

  export enum ResultCode {
    Blocked = 'BLOCKED',
    Success = 'SUCCESS',
  }

  export type Result = ResultMatchSuccess | ResultBlocked

  export interface ResultMatchSuccess {
    phone: string
    requestId: string
    resultCode: ResultCode.Success
  }

  export interface ResultBlocked {
    resultCode: ResultCode.Blocked
    /**
     * @example  'Превышено количество попыток ввода СМС-кода.
     */
    resultText: string
    phone: string
  }

  export type ResponseData = ResolveWithDataResponse | ResolveWithSystemErrorResponse
  export type ErrorResponseData = RejectedWithSystemErrorResponse
  export type RequestData = {
    maskCard: string
    mbr: number
  }
  export type RequestHeaders = null
}
