import styled from '@emotion/styled'
import { Body12 } from '@platform-ui/typography'
import { IconButton } from '@platform-ui/components/IconButton'

export const DialogCardDetailsCell = styled.div<{ isFullWidth: boolean }>(
  ({ isFullWidth, theme }) => {
    return {
      boxSizing: 'border-box',
      display: 'inline-block',
      border: `1px solid ${theme.color['surface/bravo']}`,
      margin: '-1px -1px 0 0',
      width: isFullWidth ? '100%' : 'calc(50% + .5px)',
      padding: 16,
      position: 'relative',

      ':first-of-type': {
        borderRadius: '16px 16px 0 0',
      },
      ':last-child': {
        borderRadius: '0 0 16px 16px',
      },
    }
  }
)

export const DialogCardDetailsTextWrapper = styled.div(() => {
  return {
    display: 'inline-block',
  }
})

export const DialogCardDetailsTitle = styled(Body12)(({ theme }) => {
  return {
    marginBottom: 4,
    color: theme.color['text/secondary'],
  }
})

export const DialogCardDetailsIconButton = styled(IconButton)(() => {
  return {
    position: 'absolute',
    right: 8,
  }
})
