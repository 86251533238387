import { useEffect } from 'react'
import {
  useGetCardPropertyMutation,
  GetCardPropertyModel,
} from '@/api/digital-card/getCardProperty'
import { ResponseType } from '@/models/ResponseType'

export const useDialogCardDetailsState = (
  maskCardNumber: string,
  contractNumber: string,
  cardMBR: number
):
  | {
      status: 'idle' | 'loading' | 'error'
      data: null
    }
  | {
      status: 'success'
      data: {
        phone: string
        blockedMessage?: string
        requestId?: string
      }
    } => {
  const { status, data, mutateAsync } = useGetCardPropertyMutation()

  useEffect(() => {
    mutateAsync({
      maskCardNumber,
      contractNumber,
      cardMBR,
    })
  }, [])

  if (status === 'success' && data?.data?.type === ResponseType.ResolveWithData) {
    return {
      status,
      data: {
        phone: data.data.result.phone,
        requestId:
          data.data.result.resultCode === GetCardPropertyModel.ResultCode.Success
            ? data.data.result.requestId
            : '',
        blockedMessage:
          data.data.result?.resultCode === GetCardPropertyModel.ResultCode.Blocked
            ? data.data.result.resultText
            : '',
      },
    }
  }

  return {
    status,
    data: null,
  }
}
