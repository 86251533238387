import React from 'react'
import { TabBar } from './containers/TabBar'
import { History } from '@/features/history'
import { color } from '@platform-ui/core'
// import { Period } from './containers/Period'
import { Covenant } from './containers/Covenant'
// import { PaymentSchedule } from './containers/PaymentSchedule'
import { Deposit } from './components/Deposit'
import { ProductsModel } from '@/api/products/products'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { TRANSACTION_TYPE } from '@/features/transfers-list/constants'

export interface HomeCardDetailsProps {
  productId: string
  maskCardNumber: string
  accountNumber: string
  contractNumber: string
  lastFourCardDigits: string
  productName: string
  stage: ProductsModel.ConsumerCreditInformationStage
  nextPaymentDate: string
  creditCardTWPolzaGuiStatus: ProductsModel.CreditCardTWPolzaGuiStatus
  annuityPayment: number
}

export const HomeCardDetails = ({
  productId,
  maskCardNumber,
  accountNumber,
  contractNumber,
  lastFourCardDigits,
  productName,
  stage,
  nextPaymentDate,
  creditCardTWPolzaGuiStatus,
  annuityPayment,
}: HomeCardDetailsProps) => {
  const { eventBus } = useAppContext<AppContext>()

  const onOpenModal = () => {
    eventBus.transfersModal.open.emit({
      productId,
      contractNumber,
      type: TRANSACTION_TYPE.betweenAccounts,
      isHaveInitTo: true,
    })
  }

  return (
    <>
      <TabBar productId={productId} contractNumber={contractNumber} />
      {(stage === ProductsModel.ConsumerCreditInformationStage.SpendingStage ||
        stage === ProductsModel.ConsumerCreditInformationStage.ScheduleFormingStage) && (
        <Covenant contractNumber={contractNumber} stage={stage} nextPaymentDate={nextPaymentDate} />
      )}

      {/*<PaymentSchedule contractNumber={contractNumber} />*/}

      {(creditCardTWPolzaGuiStatus ===
        ProductsModel.CreditCardTWPolzaGuiStatus.TooEarlyToPayBothPayments ||
        creditCardTWPolzaGuiStatus ===
          ProductsModel.CreditCardTWPolzaGuiStatus.PaymentRecommendedBothPayments ||
        creditCardTWPolzaGuiStatus ===
          ProductsModel.CreditCardTWPolzaGuiStatus.PaymentDayBothPayments) && (
        <Deposit
          desc="Рекомендованный платёж"
          amount={annuityPayment}
          onClick={onOpenModal}
          buttonText="Пополнить"
        />
      )}

      <History
        productType="creditCard"
        maskCardNumber={maskCardNumber}
        accountNumber={accountNumber}
        contractNumber={contractNumber}
        lastFourCardDigits={lastFourCardDigits}
        productName={productName}
        startContractDate="2021-02-01"
        bgColor={color['products/rebranding/days']}
        currencyType="RUB"
      />
    </>
  )
}
