import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { MutateOptions, useMutation } from 'react-query'
import { adapter } from './adapters/adapter'
import { ResendSmsModel } from './ResendSmsModel'
import { stringTemplate } from '@/utils/string-template'

export const resendSms: ResendSmsModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.digitalCard.resendSms,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.get<
    ResendSmsModel.ResponseData,
    void,
    ResendSmsModel.RequestHeaders,
    ResendSmsModel.ErrorResponse
  >({
    url: stringTemplate<{ requestId: string }>(path, {
      requestId: props.requestId,
    }),
    headers: {
      _ver_: props.apiVersion,
      _os_: '4',
    },
    responseAdapter: adapter,
    control,
  })
  return response
}

export function useResendSmsMutation(
  options?: MutateOptions<
    ResendSmsModel.Response,
    ResendSmsModel.ErrorResponse,
    ResendSmsModel.Props
  >
) {
  return useMutation(resendSms, options)
}
