import { useState } from 'react'
import { SplitedCodeInputsProps } from '@platform-ui/components/SplitedCodeInputs'
import { usePinCheckSmsMutation, PinCheckSmsModel } from '@/api/cardActivation/pinCheckSms'
import { useResendSmsMutation } from '@/api/cardActivation/resendSms'
import { useSendSmsMutation, SendSmsModel } from '@/api/cardActivation/sendSms'
import { ResponseType } from '@/models/ResponseType'
import { useSMSCodeStore } from './useSMSCodeStore'
import { pinCodeWidgetSteps } from '@/features/pin-code-modal/PinCodeContent'
import { useStore } from '@/features/pin-code-modal/store'

export const useSmsCodeState = (sessionUID, pin) => {
  const [codeValue, setCodeValue] = useState('')
  const [splitedCodeStatus, setSplitedCodeStatus] =
    useState<SplitedCodeInputsProps['status']>('default')

  const timerStore = useSMSCodeStore()
  const widgetStore = useStore()

  const [resendSmsAvailable, setResendSmsAvailable] = useState(false)

  const sendSmsMutation = useSendSmsMutation()

  const openFormSms = async () => {
    let sendSmsMutationResponse: SendSmsModel.Response
    try {
      sendSmsMutationResponse = await sendSmsMutation.mutateAsync({
        sessionUID: sessionUID,
      })
      if (sendSmsMutationResponse?.data.type === ResponseType.ResolveWithData) {
        setResendSmsAvailable(sendSmsMutationResponse.data.resendSmsAvailable)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const [mismatchResultText, setMismatchResultText] = useState('')

  const handleChangeSplitedCode = (value: string) => {
    setSplitedCodeStatus('default')
    setCodeValue(value)

    /**
     * Если остался текст mismatch, то убрать его после ввода смс
     */
    if (mismatchResultText) {
      setMismatchResultText('')
    }
  }

  const pinCheckSmsMutation = usePinCheckSmsMutation()

  const submitCheck = async () => {
    setSplitedCodeStatus('success')

    let pinCheckSmsMutationResponse: PinCheckSmsModel.Response
    try {
      pinCheckSmsMutationResponse = await pinCheckSmsMutation.mutateAsync({
        smsCode: codeValue,
        pin: pin,
        sessionUID: sessionUID,
      })
    } catch (error) {
      console.log('error', error)
    }

    if (pinCheckSmsMutationResponse?.data.type === ResponseType.ResolveWithData) {
      if (
        pinCheckSmsMutationResponse?.data?.result.resultCode === PinCheckSmsModel.ResultCode.Match
      ) {
        setSplitedCodeStatus('success')
        setMismatchResultText('Код введен верно')
        widgetStore.step = pinCodeWidgetSteps.SMS_CODE_SUCCESS
      }

      /**
       * Отобразить ошибку "код введен не верно"
       */
      if (
        pinCheckSmsMutationResponse?.data?.result.resultCode ===
        PinCheckSmsModel.ResultCode.Mismatch
      ) {
        setSplitedCodeStatus('error')
        setCodeValue('')
        setMismatchResultText('Код введен не верно')
        setResendSmsAvailable(pinCheckSmsMutationResponse.data.result.resendSmsAvailable)
      }

      /**
       * Превышено кол-во попыток ввода смс
       */
      if (
        pinCheckSmsMutationResponse?.data?.result.resultCode === PinCheckSmsModel.ResultCode.Error
      ) {
        widgetStore.step = pinCodeWidgetSteps.SMS_CODE_ERROR
      }
    }
  }

  const handleFulfilledSplitedCode = () => {
    submitCheck()
  }

  const handleClick = async () => {
    /**
     * Уменьшение кол-ва попыток отправки кода
     */
    timerStore.decrementAttempt()
    try {
      await resendSmsMutation.mutateAsync({
        sessionUID,
      })
    } catch (error) {
      console.log('error', error)
    }
  }

  const resendSmsMutation = useResendSmsMutation()

  return {
    codeValue,
    handleClick,
    timerStore,
    splitedCodeStatus,
    handleChangeSplitedCode,
    handleFulfilledSplitedCode,
    resendSmsAvailable,
    statusPinCheckSms: pinCheckSmsMutation.status,
    statusSendSms: sendSmsMutation.status,
    statusResendSms: resendSmsMutation.status,
    mismatchResultText,
    openFormSms,
  }
}
