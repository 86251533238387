import { HttpClientModel } from '@/core'
import { ResponseType } from '@/models/ResponseType'
import { ResolveWithSystemErrorResponse } from '@/models/ResolveWithSystemErrorResponse'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'

export namespace CheckSmsModel {
  export type Func = (props: Props) => Promise<Response>
  export type Props = {
    otp: string
    requestId: string
    apiVersion: string
  }
  export type Response = HttpClientModel.Response<ResponseData>
  export type ErrorResponse = HttpClientModel.ErrorResponse<ErrorResponseData>
  export type ResolveWithDataResponse = {
    type: ResponseType.ResolveWithData
    result: Result
  }

  export type Result = ResultMatchSuccess | ResultMismatch | ResultBlocked | ResultNotFound

  export interface ResultMatchSuccess {
    cvv: string
    pan: string
    cardHolder: string
    expireDate: string
    resendNum: number
    verifyNum: number
    resultCode: string
    resultText: string
  }

  export interface ResultMismatch {
    resultCode: string
    resultText: string
    resendNum: number
    verifyNum: number
  }

  export interface ResultBlocked {
    resultCode: ResultCode.Blocked
    /**
     * @example  'В целях вашей безопасности просмотр данных карты ограничен на 59 минут'
     */
    resultText: string
  }

  export interface ResultNotFound {
    resultCode: ResultCode.NotFound
    /**
     * @example "От сервера пришел не известный код ответа"
     */
    resultText: string
  }

  export enum ResultCode {
    Match = 'MATCH',
    Mismatch = 'MISMATCH',
    Blocked = 'BLOCKED',
    NotFound = 'NOTFOUND',
  }

  export type ResponseData = ResolveWithDataResponse | ResolveWithSystemErrorResponse
  export type ErrorResponseData = RejectedWithSystemErrorResponse
  export type RequestData = {
    otp: string
  }
  export interface RequestHeaders {
    _ver_: string
    _os_: string
  }
}
