import React from 'react'
import {
  DialogCardDetailsCell,
  DialogCardDetailsTextWrapper,
  DialogCardDetailsTitle,
  DialogCardDetailsIconButton,
} from './styled'
import { Headline16 } from '@platform-ui/typography'
import { CopyIcon } from '@platform-ui/icons/CopyIcon'

export type CardDetailsProps = {
  cardDetailsData: {
    id: string
    title: string
    description: string
    isFullWidth: boolean
  }[]
}

export const DialogCardDetails = ({ cardDetailsData }: CardDetailsProps) => {
  return (
    <>
      {cardDetailsData.map((item) => {
        return (
          <DialogCardDetailsCell key={item.id} isFullWidth={item.isFullWidth}>
            <DialogCardDetailsTextWrapper>
              <DialogCardDetailsTitle>{item.title}</DialogCardDetailsTitle>
              <Headline16>{item.description}</Headline16>
            </DialogCardDetailsTextWrapper>
            <DialogCardDetailsIconButton
              onClick={() => navigator.clipboard.writeText(item.description)}
            >
              <CopyIcon color="text/main" />
            </DialogCardDetailsIconButton>
          </DialogCardDetailsCell>
        )
      })}
    </>
  )
}
