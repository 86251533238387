import { HttpClientModel } from '@/core'
import { ResponseType } from '@/models/ResponseType'
import { ResolveWithSystemErrorResponse } from '@/models/ResolveWithSystemErrorResponse'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'

export namespace GetCardStatusModel {
  export type Func = (props: Props) => Promise<Response>
  export interface Props {
    maskCardNumber: string
  }

  export type Response = HttpClientModel.Response<ResponseData>
  export type ErrorResponse = HttpClientModel.ErrorResponse<ErrorResponseData>
  export type ResolveWithDataResponse = {
    type: ResponseType.ResolveWithData
    resultCode: ResultCode
    sessionUID: string
    mobilePhone: string
  }

  export enum ResultCode {
    Generate = 'GENERATE',
    Regenerate = 'REGENERATE',
  }

  export type ResponseData = ResolveWithDataResponse | ResolveWithSystemErrorResponse
  export type ErrorResponseData = RejectedWithSystemErrorResponse
  export type RequestData = {
    maskCard: string
  }
  export type RequestHeaders = null
}
