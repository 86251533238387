import styled from '@emotion/styled'

export const LockWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: theme.color['surface/secondary'],
  justifyContent: 'space-between',
  minHeight: '76px',
  padding: '16px 20px',
  alignContent: 'center',
  borderRadius: 12,
  marginTop: 1,
  marginBottom: 16,
  gap: 12,

  [`${theme.media.md}`]: {
    marginBottom: 40,
    flexDirection: 'row',
  },
}))

export const RightContent = styled.div(() => ({
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  gap: 12,
}))

export const MainText = styled.p(({ theme }) => ({
  whiteSpace: 'nowrap',
  ...theme.typography['body/14'],
  margin: 0,

  [`${theme.media.md}`]: {
    ...theme.typography['body/16'],
  },
}))

export const IconStyled = styled.div(({ theme }) => ({
  background: theme.color['surface/main'],
  borderRadius: 20,
  width: 24,
  height: 24,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '& > div': {
    width: 16,
    height: 16,
  },
}))
