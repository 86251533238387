import { useEffect } from 'react'
import {
  useGetInfoInterbankTransferMutation,
  GetInfoInterbankTransferModel,
} from '@/api/payment/getInfoInterbankTransfer'
import { ResponseType } from '@/models/ResponseType'

export const useDialogBankDetailsState = (
  maskCardNumber: string,
  contractNumber: string,
  cardMBR: number
):
  | {
      status: 'idle' | 'loading' | 'error'
      data: null
    }
  | {
      status: 'success'
      data: GetInfoInterbankTransferModel.ResolveWithDataResponse
    } => {
  const { status, data, mutateAsync } = useGetInfoInterbankTransferMutation()

  useEffect(() => {
    mutateAsync({
      maskCardNumber,
      contractNumber,
      cardMBR,
    })
  }, [])

  if (status === 'success' && data?.data?.type === ResponseType.ResolveWithData) {
    return {
      status,
      data: data.data,
    }
  }

  return {
    status: status,
    data: null,
  }
}
