import { createSelector } from 'reselect'
import { UseQueryResult } from 'react-query'
import { useSettingsQuery, SettingsModel } from '@/api/settings/settings'
import { useGetCardStatusQuery, GetCardStatusModel } from '@/api/cardActivation/getCardStatus'
import { ApiStatus, useAppContext } from '@/core'
import { ResponseType } from '@/models/ResponseType'
import { ProductsModel } from '@/api/products/products'
import { AppContext } from '@/models/AppContext'
import { pinCodeVariants } from '@/features/pin-code-modal/PinCodeContent'

interface State {
  checkCardStatus: UseQueryResult<GetCardStatusModel.ResponseData, GetCardStatusModel.ErrorResponse>
  settings: UseQueryResult<SettingsModel.ResponseData, SettingsModel.ErrorResponse>
  cardStatusDisplayed: string
  isPlasticActivationAvailable: boolean
}

interface Data {
  title: string
  desc: string
  buttonText: string
  buttonClick: () => void
}

const selector = createSelector(
  (state: State) => state.checkCardStatus.data,
  (state: State) => state.checkCardStatus.status,
  (state: State) => state.checkCardStatus.refetch,
  (state: State) => state.settings.data,
  (state: State) => state.settings.status,
  (state: State) => state.cardStatusDisplayed,
  (state: State) => state.isPlasticActivationAvailable,
  (
    checkCardStatusResponse,
    checkCardStatus,
    checkCardStatusRefetch,
    settingsResponse,
    settingsStatus,
    cardStatusDisplayed,
    isPlasticActivationAvailable
  ):
    | {
        status: ApiStatus.Idle
        data: null
        hidePinCodeChangeTab: boolean
        sessionUID: string
        mobilePhone: string
        retry: () => void
      }
    | {
        status: ApiStatus.Pending
        data: null
        hidePinCodeChangeTab: boolean
        sessionUID: string
        mobilePhone: string
        retry: () => void
      }
    | {
        status: ApiStatus.Rejected
        data: null
        hidePinCodeChangeTab: boolean
        sessionUID: string
        mobilePhone: string
        retry: () => void
      }
    | {
        status: ApiStatus.Fulfilled
        data: Data
        hidePinCodeChangeTab: boolean
        sessionUID: string
        mobilePhone: string
        retry: () => void
      } => {
    let status
    let data = null
    let sessionUID
    let mobilePhone

    const { eventBus } = useAppContext<AppContext>()

    if (checkCardStatus === 'success' || settingsStatus === 'success') {
      status = ApiStatus.Fulfilled
    }

    if (checkCardStatus === 'loading' || settingsStatus === 'loading') {
      status = ApiStatus.Pending
    }

    if (checkCardStatus === 'error' || settingsStatus === 'error') {
      status = ApiStatus.Rejected
    }

    if (checkCardStatusResponse && settingsResponse) {
      if (
        checkCardStatusResponse.type === ResponseType.ResolveWithData &&
        settingsResponse.type === ResponseType.ResolveWithData
      ) {
        const isGenerate =
          checkCardStatusResponse.resultCode === GetCardStatusModel.ResultCode.Generate
        const isRegenerate =
          checkCardStatusResponse.resultCode === GetCardStatusModel.ResultCode.Regenerate
        const enableDebitCardPinGeneration = settingsResponse.result.enableDebitCardPinGeneration
        sessionUID = checkCardStatusResponse.sessionUID
        mobilePhone = checkCardStatusResponse.mobilePhone
        if (
          isPlasticActivationAvailable &&
          enableDebitCardPinGeneration &&
          (isGenerate || isRegenerate)
        ) {
          data = {
            title: 'Карта доставлена',
            desc: 'Активируйте вашу пластиковую карту',
            buttonText: 'Активировать',
            buttonClick: () => {
              eventBus.pinCodeModal.open.emit({
                variant: pinCodeVariants.ACTIVATION,
                sessionUID: sessionUID,
                mobilePhone: mobilePhone,
              })
            },
          }
        } else {
          if (
            cardStatusDisplayed === ProductsModel.CardStatusDisplayed.NotActive &&
            enableDebitCardPinGeneration &&
            (isGenerate || isRegenerate)
          ) {
            data = {
              title: 'Карта доставлена',
              desc: 'Всего один шаг и можно идти за покупками',
              buttonText: 'Активировать',
              buttonClick: () => {
                eventBus.pinCodeModal.open.emit({
                  variant: pinCodeVariants.ACTIVATION,
                  sessionUID: sessionUID,
                  mobilePhone: mobilePhone,
                })
              },
            }
          }
          if (
            cardStatusDisplayed === ProductsModel.CardStatusDisplayed.Active &&
            enableDebitCardPinGeneration &&
            isGenerate
          ) {
            data = {
              title: 'Осталось установить ПИН‑код',
              desc: 'Чтобы использовать банкоматы и совершать покупки, создайте ПИН‑код.',
              buttonText: 'Установить ПИН-код',
              buttonClick: () => {
                eventBus.pinCodeModal.open.emit({
                  variant: pinCodeVariants.CREATE,
                  sessionUID: sessionUID,
                  mobilePhone: mobilePhone,
                })
              },
            }
          }
        }
      }
    }

    return {
      status,
      data,
      hidePinCodeChangeTab: !!data,
      sessionUID: sessionUID,
      mobilePhone: mobilePhone,
      retry: checkCardStatusRefetch,
    }
  }
)

export function useGetWidgetInfoSelector(
  cardStatusDisplayed: ProductsModel.CardStatusDisplayed,
  isPlasticActivationAvailable: boolean,
  maskCardNumber: string
) {
  const checkCardStatus = useGetCardStatusQuery({
    maskCardNumber,
  })

  const settings = useSettingsQuery()

  return selector({ checkCardStatus, settings, cardStatusDisplayed, isPlasticActivationAvailable })
}
