export enum pinCodeWidgetSteps {
  PIN_CODE_FORM = 'PIN_CODE_FORM',
  SMS_CODE_FORM = 'SMS_CODE_FORM',
  SMS_CODE_ERROR = 'SMS_CODE_ERROR',
  SMS_CODE_SUCCESS = 'SMS_CODE_SUCCESS',
}

export enum pinCodeVariants {
  ACTIVATION = 'ACTIVATION',
  CREATE = 'CREATE',
  CHANGE = 'CHANGE',
}

export const pinCodeContent = {
  [pinCodeVariants.ACTIVATION]: {
    title: 'Активация карты',
    pages: {
      pin: {
        title: 'Придумайте ПИН‑код',
        hint: 'Рекомендуем записать или запомнить указанный вами Пин-код. В целях безопасности не храните его рядом с вашей картой и не передавайте третьим лицам',
      },
      finalSuccess: {
        title: 'Карта успешно активирована',
        hint: 'Используйте все возможности: оплачивайте покупки в любимых магазинах, вносите платежи или снимайте наличные',
      },
    },
  },
  [pinCodeVariants.CREATE]: {
    title: 'Создание ПИН-кода',
    pages: {
      pin: {
        title: 'Придумайте ПИН‑код',
        hint: 'Рекомендуем записать или запомнить указанный вами Пин-код. В целях безопасности не храните его рядом с вашей картой и не передавайте третьим лицам',
      },
      finalSuccess: {
        title: 'ПИН-код успешно создан',
        hint: 'Используйте все возможности: оплачивайте покупки в любимых магазинах, вносите платежи или снимайте наличные',
      },
    },
  },
  [pinCodeVariants.CHANGE]: {
    title: 'Смена ПИН-кода',
    pages: {
      pin: {
        title: 'Придумайте ПИН‑код',
        hint: 'Рекомендуем записать или запомнить указанный вами Пин-код. В целях безопасности не храните его рядом с вашей картой и не передавайте третьим лицам',
      },
      finalSuccess: {
        title: 'ПИН-код успешно изменен',
        hint: 'Используйте все возможности: оплачивайте покупки в любимых магазинах, вносите платежи или снимайте наличные',
      },
    },
  },
}
