import React from 'react'
import { useRouter } from '@/next/router'
import { ApiStatus } from '@/core'
import { ContentHeader } from '@/components/ContentHeader'
import { CardsSlider } from '@/features/cards-slider'
import { DebitCardDetails } from '@/features/debit-card-details'
import { useGetCardsPageProductsSelector } from './selectors/useGetCardsPageProductsSelector'
import { ProductsModel } from '@/api/products/products'
import { ContentError } from '@/components/ContentError'
import { CreditCardDetail } from '@/features/credit-card'
import { HomeCardDetails } from '@/features/home-card-details'

export const CardsPage = () => {
  const router = useRouter()
  const productId = router.query.productId as string

  const { status, products } = useGetCardsPageProductsSelector()

  const isLoading = status === ApiStatus.Pending
  const isServerError = status === ApiStatus.Rejected
  const isProductNotFound = status === ApiStatus.Fulfilled && !products[productId]

  /**
   * Если ошибка от сервера / продукт не найден => показываем компонент с ошибкой
   * Макета нет
   */
  if (
    isServerError ||
    isProductNotFound ||
    (!isLoading &&
      products[productId]?.productType !== ProductsModel.ProductType.DebitCard &&
      products[productId]?.productType !== ProductsModel.ProductType.CreditCard)
  ) {
    return <ContentError />
  }

  const productById = products[productId]

  const isDebitCard = !isLoading && productById.productType === ProductsModel.ProductType.DebitCard
  /**
   * Хоум карта считается кредитной, но имеет отличия от остальных кредитных карт.
   * Поэтому нужна проверка и отдельная features.
   */
  const isCreditCard =
    !isLoading &&
    productById.productType === ProductsModel.ProductType.CreditCard &&
    !productById.isHomeCard
  const isHomeCard =
    !isLoading &&
    productById.productType === ProductsModel.ProductType.CreditCard &&
    productById.isHomeCard
  const title = (isDebitCard || isCreditCard || isHomeCard) && productById.productName
  const description = !isLoading && `${productById.description} • ${productById.lastFourCardDigits}`

  return (
    <>
      <ContentHeader isLoading={isLoading} title={title} description={description} />
      <CardsSlider productId={productId} />
      {isDebitCard && <DebitCardDetails productId={productId} />}
      {isCreditCard && (
        <CreditCardDetail
          maskCardNumber={productById.maskCardNumber}
          productId={productById.id}
          cardMBR={productById.mbr}
          cardStatus={productById.cardStatusDisplayed}
          cardType={productById.cardType}
          contractStatus={productById.contractStatus}
          product={productById}
        />
      )}
      {isHomeCard && (
        <HomeCardDetails
          productId={productId}
          maskCardNumber={productById.maskCardNumber}
          accountNumber={productById.accountNumber}
          contractNumber={productById.contractNumber}
          lastFourCardDigits={productById.lastFourCardDigits}
          productName={productById.productName}
          stage={productById.consumerCreditInformation.stage}
          nextPaymentDate={productById.contract.properties.nextPaymentDate}
          creditCardTWPolzaGuiStatus={productById.creditCardTWGuiData.creditCardTWPolzaGuiStatus}
          annuityPayment={productById.consumerCreditInformation.annuityPayment}
        />
      )}
    </>
  )
}
