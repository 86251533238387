import React from 'react'
import { Headline20 } from '@platform-ui/typography'
import { Button } from '@platform-ui/components/Button'
import Error from '@/assets/Error.png'
import Image from '@/next//image'
import { TextWrapper, ImageStyled, TextStyled, DialogErrorWrapper } from './styled'
import styled from '@emotion/styled'

export const ButtonWrapper = styled.div(() => ({
  position: 'fixed',
  padding: '32px 16px',
  width: '100%',
  left: 0,
  bottom: 0,
}))

export type DialogErrorProps = {
  goBack: () => void
}

export const DialogError = ({ goBack }: DialogErrorProps) => {
  return (
    <DialogErrorWrapper>
      <TextWrapper>
        <ImageStyled>
          <Image src={Error} width={108} height={108} alt="Error" />
        </ImageStyled>
        <Headline20>Произошла ошибка</Headline20>
        <TextStyled>
          Мы работаем над этой проблемой.
          <br />
          Приносим извинения за неудобства.
        </TextStyled>
      </TextWrapper>

      <ButtonWrapper>
        <Button isFullWidth onClick={goBack}>
          Вернуться назад
        </Button>
      </ButtonWrapper>
    </DialogErrorWrapper>
  )
}
