import React from 'react'
import { observer } from 'mobx-react-lite'
import { BankDetailsShimmer } from '@/components/BankDetailsShimmer'
import { DialogError } from '@/components/DialogError'
import { DialogBankDetailsSuccess } from './DialogBankDetailsSuccess'
import { useDialogBankDetailsState } from './useDialogBankDetailsState'
import { CurrencyType } from '@/models/CurrencyType'
import { GetInfoInterbankTransferModel } from '@/api/payment/getInfoInterbankTransfer'

export const getCorrBankSwift = (
  currencyType: CurrencyType,
  data: GetInfoInterbankTransferModel.ResolveWithDataResponse
): {
  corrBankSwift: string
  corrBankName: string
  corrAcc: string
} => {
  if (currencyType === 'USD')
    return {
      corrBankSwift: data.corrBankSwiftUsd,
      corrBankName: data.corrBankNameUsd,
      corrAcc: data.corrAccUsd,
    }
  if (currencyType === 'EUR')
    return {
      corrBankSwift: data.corrBankSwiftEur,
      corrBankName: data.corrBankNameEur,
      corrAcc: data.corrAccEur,
    }
  return {
    corrBankSwift: '',
    corrBankName: '',
    corrAcc: data.corrAcc,
  }
}

export type DialogBankDetailsProps = {
  goBack: () => void
  maskCardNumber: string
  contractNumber: string
  cardMBR: number
  currencyType: CurrencyType
  accountNumber: string
}

export const DialogBankDetails = observer(
  ({
    goBack,
    maskCardNumber,
    contractNumber,
    cardMBR,
    currencyType,
    accountNumber,
  }: DialogBankDetailsProps) => {
    const { status, data } = useDialogBankDetailsState(maskCardNumber, contractNumber, cardMBR)

    if (status === 'loading') {
      return <BankDetailsShimmer />
    }
    if (status === 'error') {
      return <DialogError goBack={goBack} />
    }

    if (status === 'success') {
      const { clientFio, bankNum, bankINN, bankSwift } = data

      const { corrBankSwift, corrBankName, corrAcc } = getCorrBankSwift(currencyType, data)
      const bankName = currencyType === 'RUB' ? data.bankName : data.bankNameEng

      return (
        <DialogBankDetailsSuccess
          clientFio={clientFio}
          bankName={bankName}
          corrAcc={corrAcc}
          bankNum={bankNum}
          bankINN={bankINN}
          bankSwift={bankSwift}
          corrBankSwift={corrBankSwift}
          corrBankName={corrBankName}
          currencyType={currencyType}
          accountNumber={accountNumber}
        />
      )
    }

    return null
  }
)
