import { AdapterModel } from './AdapterModel'
import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { HttpClient } from '@/core'
import { CheckSmsModel } from '../CheckSmsModel'

/**
 * @see https://stackoverflow.com/questions/7860392/determine-if-string-is-in-base64-using-javascript
 */
const isBase64 = (str: string) => {
  if (!str) return str
  if (str === '' || str.trim() === '') {
    return str
  }
  try {
    return window.btoa(window.atob(str)) == str
  } catch (err) {
    return str
  }
}

const createResult = (successData: AdapterModel.ResponseDataSuccess): CheckSmsModel.Result => {
  if (successData.resultCode === CheckSmsModel.ResultCode.Match) {
    const result: CheckSmsModel.ResultMatchSuccess = {
      resultText: successData?.resultText,
      resultCode: CheckSmsModel.ResultCode.Match,
      cvv: isBase64(successData.cvv) ? window.atob(successData.cvv) : successData.cvv,
      pan: isBase64(successData.pan) ? window.atob(successData.pan) : successData.pan,
      cardHolder: isBase64(successData.cardHolder)
        ? window.atob(successData.cardHolder)
        : successData.cardHolder,
      expireDate: successData.expireDate,
      resendNum: successData?.resendNum,
      verifyNum: successData?.verifyNum,
    }

    return result
  }

  if (successData.resultCode === CheckSmsModel.ResultCode.Mismatch) {
    const result: CheckSmsModel.ResultMismatch = {
      resultCode: successData.resultCode,
      resultText: successData?.resultText,
      resendNum: successData.resendNum,
      verifyNum: successData.verifyNum,
    }
    return result
  }

  if (successData.resultCode === CheckSmsModel.ResultCode.Blocked) {
    const result: CheckSmsModel.ResultBlocked = {
      resultCode: successData.resultCode,
      resultText: successData?.resultText,
    }
    return result
  }

  const result: CheckSmsModel.ResultNotFound = {
    resultCode: CheckSmsModel.ResultCode.NotFound,
    resultText: successData?.resultText || 'От сервера пришел не известный код ответа',
  }
  return result
}

export const adapter: AdapterModel.Func = (data) => {
  const errorData = data as AdapterModel.ResponseDataError
  /**
   * Сервер всегда возвращает status=200 в заголовке
   * Реальный статус приходит в теле ответа
   * 401 или 500+ считаем rejected ответами с наличием критической ошибки
   */
  if (errorData?.statusCode === 401 || errorData?.statusCode >= 500) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: errorData.errorDetails
        ? errorData.errorDetails.map(({ error }) => ({
            type: error,
            message: error,
          }))
        : [],
    }
    throw HttpClient.createError(errorData?.statusCode, exception)
  }

  try {
    const successData = data as AdapterModel.ResponseDataSuccess
    return {
      type: ResponseType.ResolveWithData,
      result: createResult(successData),
    }
  } catch (error) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [{ message: 'Ошибка при обработке полученных данных', type: 'ADAPTER_ERROR' }],
    }

    throw HttpClient.createError(500, exception)
  }
}
