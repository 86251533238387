import styled from '@emotion/styled'
import { Button } from '@platform-ui/components/Button'
import { Body12 } from '@platform-ui/typography'

export const DialogBankDetailsCellWrapper = styled.div(({ theme }) => ({
  [`${theme.media.md}`]: {
    marginTop: 1,
    marginBottom: 110,
  },
}))

export const DialogBankDetailsCell = styled.div(({ theme }) => ({
  boxSizing: 'border-box',
  border: `1px solid ${theme.color['surface/bravo']}`,
  margin: '-1px -1px 0 0',
  width: '100%',
  padding: 16,
  position: 'relative',

  ':first-of-type': {
    borderRadius: '16px 16px 0 0',
  },
  ':last-child': {
    borderRadius: '0 0 16px 16px',
  },
}))

export const DialogBankDetailsTitle = styled(Body12)(({ theme }) => ({
  marginBottom: 4,
  color: theme.color['text/secondary'],
}))

export const DialogBankDetailsButton = styled.div(({ theme }) => ({
  width: 'calc(100% - 10px)',
  boxShadow: `none`,
  background: theme.color['background/main'],
  padding: '32px 16px 16px',
  position: 'sticky',
  left: 5,
  right: 5,
  bottom: 0,

  [`${theme.media.md}`]: {
    padding: '0 32px 32px',
    position: 'fixed',
    boxShadow: `0px -32px 16px -10px ${theme.color['background/main']}`,
    borderBottomLeftRadius: theme.borderRadius['r12'],
    borderBottomRightRadius: theme.borderRadius['r12'],
  },
}))

export const ButtonStyled = styled(Button)(() => ({
  maxWidth: '100%',
  margin: '0 auto',
}))
